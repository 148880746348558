var width = document.documentElement.clientWidth;

export const surveyJson207 = {
  title: "GRI 207：稅務",
  logoPosition: "right",
  pages: [
    {
      name: "207-1",
      elements: [
        {
          type: "panel",
          name: "207-1-panel",
          elements: [
            {
              type: "text",
              name: "207-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "207-1 稅務方針",
    },
    {
      name: "207-2",
      elements: [
        {
          type: "panel",
          name: "207-2-panel",
          elements: [
            {
              type: "text",
              name: "207-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "207-2 稅務治理、管控與風險管理",
    },
    {
      name: "207-3",
      elements: [
        {
          type: "panel",
          name: "207-3-panel",
          elements: [
            {
              type: "text",
              name: "207-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "207-3 稅務相關議題之利害關係人溝通與管理",
    },
    {
      name: "207-4",
      elements: [
        {
          type: "panel",
          name: "207-4-panel",
          elements: [
            {
              type: "text",
              name: "207-4",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "207-4 國別報告",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
