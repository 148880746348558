var width = document.documentElement.clientWidth;

export const surveyJson404 = {
  title: "GRI 404：訓練與教育 2016",
  logoPosition: "right",
  pages: [
    {
      name: "404-1",
      elements: [
        {
          type: "panel",
          name: "404-1-panel",
          elements: [
            {
              type: "text",
              name: "404-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "404-1 每名員工每年接受訓練的平均時數",
    },
    {
      name: "404-2",
      elements: [
        {
          type: "panel",
          name: "404-2-panel",
          elements: [
            {
              type: "text",
              name: "404-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "404-2 提升員工職能及過渡協助方案",
    },
    {
      name: "404-3",
      elements: [
        {
          type: "panel",
          name: "404-3-panel",
          elements: [
            {
              type: "text",
              name: "404-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "404-3 定期接受績效及職業發展檢核的員工百分比",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
