import { createPage } from './createPage';
var width = document.documentElement.clientWidth;

export const surveyJson2 = {
  title: "GRI 2: 一般揭露 2021",
  logoPosition: "right",
  pages: [
    {
      name: "2-1",
      elements: [
        {
          type: "panel",
          name: "2-1-panel",
          elements: [
            {
              type: "text",
              name: "2-1-a",
              title: "公司名稱",
            },
            {
              type: "dropdown",
              name: "2-1-a-dropdown",
              title: "",
              titleLocation: "hidden",
              placeholder: "選擇公司類型",
              choices: ["有限公司", "股份有限公司"],
            },
            {
              type: "text",
              name: "2-1",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-1 組織詳細資訊",
    },
    createPage("2-2", "2-2 組織永續報導中包含的實體"),
    {
      name: "2-3",
      elements: [
        {
          type: "panel",
          name: "2-3-panel",
          elements: [
            {
              type: "dropdown",
              name: "2-3-a-dropdown",
              title: "報導期間",
              description: "2023 年 1 月 1 日至 12 月 31 日",
              placeholder: "選擇年份",
              choices: ["2023", "2024", "2025"],
            },
            {
              type: "dropdown",
              name: "2-3-b-dropdown",
              title: "發布日期",
              description: "2024 年 6 月",
              placeholder: "選擇月份",
              choices: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ],
            },
            {
              type: "text",
              name: "2-1-a",
              title: "公司名稱",
            },
            {
              type: "dropdown",
              name: "2-1-a-dropdown",
              title: "",
              titleLocation: "hidden",
              placeholder: "選擇公司類型",
              choices: ["有限公司", "股份有限公司"],
            },
            {
              type: "text",
              name: "2-3-b",
              title: "成立日期",
              description: "1997 年5 月28 日",
            },
            {
              type: "text",
              name: "2-3-c",
              title: "上市資訊",
              description: "臺灣證券交易所 股票代碼 2454",
            },
            {
              type: "text",
              name: "2-3-d",
              title: "資本額",
              description: "15,996,450,320 元",
            },
            {
              type: "text",
              name: "2-3-e",
              title: "聯絡單位",
              description: "聯發科技企業永續發展委員會",
            },
            {
              type: "text",
              name: "2-3-f",
              title: "地址",
              description: "新竹市新竹科學工業園區篤行一路1 號",
            },
            {
              type: "text",
              name: "2-3-g",
              title: "電話",
              description: "886-3-5670766",
            },
            {
              type: "text",
              name: "2-3-h",
              title: "信箱",
              description: "csr@mediatek.com",
            },
            {
              type: "text",
              name: "2-3-i",
              title: "網站",
              description: "https://corp.mediatek.tw/",
            },
          ],
        },
      ],
      title: "2-3 報導期間、頻率及聯絡人",
    },
    {
      name: "2-4",
      elements: [
        {
          type: "panel",
          name: "2-4-panel",
          elements: [
            {
              type: "text",
              name: "2-4",
              title: "資訊重編次數與日期",
              defaultValue: "無重編資訊",
            },
          ],
        },
      ],
      title: "2-4 資訊重編",
    },
    {
      name: "2-5",
      elements: [
        {
          type: "panel",
          name: "2-5-panel",
          elements: [
            {
              type: "text",
              name: "2-5",
              title: "永續報導經外部保證/確信",
              defaultValue: "BSI英國標準協會臺灣分公司",
            },
          ],
        },
      ],
      title: "2-5 外部保證/確信",
    },
    createPage("2-6", "2-6 活動、價值鏈和其他商業關係"),
    createPage("2-7", "2-7 員工"),
    createPage("2-8", "2-8 非員工的工作者"),
    createPage("2-9", "2-9 治理結構及組成"),
    createPage("2-10", "2-10 最高治理單位的提名與遴選"),
    createPage("2-11", "2-11 最高治理單位的主席"),
    createPage("2-12", "2-12 最高治理單位於監督衝擊管理的角色"),
    createPage("2-13", "2-13 衝擊管理的負責人"),
    createPage("2-14", "2-14 最高治理單位於永續報導的角色"),
    createPage("2-15", "2-15 利益衝突"),
    {
      name: "2-16",
      elements: [
        {
          type: "panel",
          name: "2-16-panel",
          elements: [
            {
              type: "text",
              name: "2-16",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-16 溝通關鍵重大事件",
    },
    {
      name: "2-17",
      elements: [
        {
          type: "panel",
          name: "2-17-panel",
          elements: [
            {
              type: "text",
              name: "2-17",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-17 最高治理單位的群體智識",
    },
    {
      name: "2-18",
      elements: [
        {
          type: "panel",
          name: "2-18-panel",
          elements: [
            {
              type: "text",
              name: "2-18",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-18 最高治理單位的績效評估",
    },
    {
      name: "2-19",
      elements: [
        {
          type: "panel",
          name: "2-19-panel",
          elements: [
            {
              type: "text",
              name: "2-19",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-19 薪酬政策",
    },
    {
      name: "2-20",
      elements: [
        {
          type: "panel",
          name: "2-20-panel",
          elements: [
            {
              type: "text",
              name: "2-20",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-20 薪酬決定流程",
    },
    {
      name: "2-21",
      elements: [
        {
          type: "panel",
          name: "2-21-panel",
          elements: [
            {
              type: "text",
              name: "2-21",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-21 年度薪酬比率",
    },
    {
      name: "2-22",
      elements: [
        {
          type: "panel",
          name: "2-22-panel",
          elements: [
            {
              type: "text",
              name: "2-22",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-22 永續發展策略的聲明",
    },
    {
      name: "2-23",
      elements: [
        {
          type: "panel",
          name: "2-23-panel",
          elements: [
            {
              type: "text",
              name: "2-23",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-23 政策承諾",
    },
    {
      name: "2-24",
      elements: [
        {
          type: "panel",
          name: "2-24-panel",
          elements: [
            {
              type: "text",
              name: "2-24",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-24 納入政策承諾",
    },
    {
      name: "2-25",
      elements: [
        {
          type: "panel",
          name: "2-25-panel",
          elements: [
            {
              type: "text",
              name: "2-25",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-25 補救負面衝擊的程序",
    },
    {
      name: "2-26",
      elements: [
        {
          type: "panel",
          name: "2-26-panel",
          elements: [
            {
              type: "text",
              name: "2-26",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-26 尋求建議和提出疑慮的機制",
    },
    {
      name: "2-27",
      elements: [
        {
          type: "panel",
          name: "2-27-panel",
          elements: [
            {
              type: "text",
              name: "2-27",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-27 法規遵循",
    },
    {
      name: "2-28",
      elements: [
        {
          type: "panel",
          name: "2-28-panel",
          elements: [
            {
              type: "text",
              name: "2-28",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-28 公協會的會員資格",
    },
    {
      name: "2-29",
      elements: [
        {
          type: "panel",
          name: "2-29-panel",
          elements: [
            {
              type: "text",
              name: "2-29",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-29 利害關係人議合方針",
    },
    {
      name: "2-30",
      elements: [
        {
          type: "panel",
          name: "2-30-panel",
          elements: [
            {
              type: "text",
              name: "2-30",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "2-30 團體協約",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  completeText: "Submit",
  widthMode: "static",
  width: width,
};
