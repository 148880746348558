var width = document.documentElement.clientWidth;

export const surveyJson418 = {
  title: "GRI 418：客戶隱私 2016",
  logoPosition: "right",
  pages: [
    {
      name: "418-1",
      elements: [
        {
          type: "panel",
          name: "418-1-panel",
          elements: [
            {
              type: "text",
              name: "418-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "418-1 經證實侵犯客戶隱私或遺失客戶資料的投訴",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
