import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Link,
} from "@mui/material";

import SaveButton from "./SaveButton";
import { addEsg, updateEsg } from "api/esgApi.js";
import "survey-core/defaultV2.min.css";
import { Chart } from "react-google-charts";

const ContentCardChart = ({
  title,
  content,
  content_file,
  content_from,
  seqNo,
  templateId,
  type,
}) => {
  const handleSave = () => {
    const accessToken = window.localStorage.getItem("accessToken") || "";
    if (seqNo !== 0 && accessToken && content !== "") {
      updateEsg(
        {
          content: content,
          seqNo: seqNo,
        },
        accessToken
      ).then((res) => {
        console.log("updateEsg:", res);
      });
    } else if (seqNo == 0 && accessToken && content !== "") {
      addEsg(
        {
          content: content,
          esgTemplateSeqNo: templateId,
        },
        accessToken
      ).then((res) => {
        console.log("addEsg:", res);
      });
    }
  };
// 年齡分布方面，50歲以下1名、51-60歲5名、61-70歲2名、71歲以上1名
  const numbers = content.match(/\d+(?=名)/g)?.map(Number); // 使用正則表達式來匹配所有的數字
  const lastFourNumbers = numbers?.slice(-4) || Array.from({length: 4}, () => Math.floor(Math.random() * 9)+1);

  const labels = ["50歲以下", "51-60歲", "61-70歲", "71歲以上"];

  const data = labels.map((label, index) => [label, lastFourNumbers[index]]);

  // const data = [
  //   ["50歲以下", 1],
  //   ["51-60歲", 5],
  //   ["61-70歲", 2],
  //   ["71歲以上", 1],
  // ];
  data.unshift(["Task", "male female ratio"]);
  const options = {
    // legend: "none",
    // pieSliceText: "none",
    // pieStartAngle: 135,
    // tooltip: { trigger: "none" },
    title: "董事會年齡分佈",
    pieHole: 0.5,
    slices: {
      0: { color: "LightSkyBlue" },
      1: { color: "LightPink" },
    },
  };

  return (
    <>
      <Card className="mt-4">
        <CardContent>
          <Typography className="mb-4 text-lg font-bold">{title}</Typography>
          <Typography className="text-black text-lg max-w-[1020px]">{content}</Typography>
          <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={"100%"}
              height={"400px"}
          />
          
        </CardContent>
        <CardActions>
          <div className="flex justify-between items-center">
            {templateId && <SaveButton handleSave={handleSave} />}
            <Typography className="ml-2">
              <Link
                href={content_file}
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-0"
              >
                {content_from}
              </Link>
            </Typography>
          </div>
        </CardActions>
      </Card>
    </>
  );
};

export default ContentCardChart;
