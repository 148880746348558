const apiUrl = "https://xpert-api.azurefd.net"

export const sendChat = (params) => {
  return fetch(
      `${apiUrl}/api/chat`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.body)
  .then((body) => body);
};

