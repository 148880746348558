import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Model } from "survey-core";

import { surveyJsonImage } from "survey/image.js";

import "survey-core/defaultV2.min.css";


import { ContentChart } from "components/ContentChart.js";
import { postAuthLogin } from "../api/esgApi.js";


function App() {
  const [survey, setSurvey] = React.useState(null);
  const [page, setPage] = React.useState("2-11");
  const accessToken = window.localStorage.getItem("accessToken") || "";

  useEffect(() => {
    if (!accessToken) {
      postAuthLogin({
        username: "user2",
        password: "ABcd1234",
      }).then((res) => {
        window.localStorage.setItem("accessToken", res.token);
        window.localStorage.setItem("refreshToken", res.refreshToken);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    setSurvey(new Model(surveyJsonImage));
  }, []);

  useEffect(() => {
    if (survey) {
      survey.onValueChanged.add((result, options) => {
        console.log("urvey.onValueChanged ", options);
      });

      survey.onCurrentPageChanged.add(function (sender, options) {
        setPage(options.newCurrentPage.name);
      });
    }
  }, [survey]);

  return (
    <React.Fragment>
      <Box className="w-full flex-row flex">
        {/* {survey ? <Survey model={survey} /> : <Box />} */}

        <Box
          style={{
            width: "100%",
            background: "#f3f3f3",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >

          {[1,2,6,7,8,9,10,11].map(
            (pageId, index) =>
              page === `2-${pageId}` && (
                <ContentChart survey={survey} id={`2-${pageId}`} type="chart" />
              )
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default App;
