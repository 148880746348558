var width = document.documentElement.clientWidth;

export const surveyJson402 = {
  title: "GRI 402：勞資關係",
  logoPosition: "right",
  pages: [
    {
      name: "402-1",
      elements: [
        {
          type: "panel",
          name: "402-1-panel",
          elements: [
            {
              type: "text",
              name: "402-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "402-1 關於營運變化的最短預告期",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
