const apiEsg = process.env.REACT_APP_EX_API
// "https://api-esg-frontend.azurewebsites.net";

export const postAuthLogin = (params) => {
  return fetch(`${apiEsg}/api/token`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
    }),
  }).then((response) => response.json());
};

export const postRefreshToken = () => {
  const refreshToken = window.localStorage.getItem("refreshToken");
  const accessToken = window.localStorage.getItem("accessToken");

  if (!refreshToken || !accessToken) return;

  if (refreshToken === "undefined")
    window.localStorage.removeItem("refreshToken");
  if (accessToken === "undefined")
    window.localStorage.removeItem("accessToken");

  const params = {
    refreshToken: refreshToken,
    token: accessToken,
  };
  return fetch(`${apiEsg}/api/refresh-token`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
    }),
  }).then((response) => response.json());
};

export const getTemplate = (id, token) => {
  const IndustryName = localStorage.getItem('IndustryName') || '食品業';

  return fetch(
    `${apiEsg}/api/esg/template?IndustryName=${IndustryName}&ESGDetailCode=${id}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": "en-US",
        Authorization: `Bearer ${token}`,
      }),
    }
  ).then((response) => response.json());
};

export const getEsgAll = (token) => {
  const IndustryName = localStorage.getItem('IndustryName') || '食品業';
  return fetch(
    `${apiEsg}/api/esg/all?IndustryName=${IndustryName}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": "en-US",
        Authorization: `Bearer ${token}`,
      }),
    }
  ).then((response) => response.json());
};

export const getEsg = (id, token) => {
  const IndustryName = localStorage.getItem('IndustryName') || '食品業';

  return fetch(`${apiEsg}/api/esg?IndustryName=${IndustryName}&ESGDetailCode=${id}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => {
      console.log('getEsg:', response)
      if(response.status==500) {
        console.log('500 no data')
        return null
      };
      if (!response.ok) {
        postRefreshToken().then((resToken) => {
          window.localStorage.setItem("accessToken", resToken.token);
          window.localStorage.setItem("refreshToken", resToken.refreshToken);
          // window.location.reload();
        });
      } else {
        return response.status === 204 ? "" : response.json();
      }
    })
    .catch((error) => console.error("Error:", error));
};

export const addEsg = (params, token) => {
  return fetch(`${apiEsg}/api/esg/create`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
      Authorization: `Bearer ${token}`,
    }),
  }).then((response) => response.json());
};

export const updateEsg = (params, token) => {
  return fetch(`${apiEsg}/api/esg/update`, {
    method: "PUT",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => response) // no response
    .catch((error) => console.error("Error:", error));
};


export const getEsgReport = (token, curIndustry) => {
  return fetch(`${apiEsg}/api/esg/report?IndustryName=${curIndustry}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => {
      if(response.status==500) {
        console.log('500 no data')
        return null
      };
      if (!response.ok) {
        postRefreshToken().then((resToken) => {
          window.localStorage.setItem("accessToken", resToken.token);
          window.localStorage.setItem("refreshToken", resToken.refreshToken);
          // window.location.reload();
        });
      } else {
        return response.status === 204 ? "" : response.json();
      }
    })
    .catch((error) => console.error("Error:", error));
};

export const getEsgLayout = (token) => {
  const IndustryName = localStorage.getItem('IndustryName') || '食品業';
  return fetch(`${apiEsg}/api/esglayout?IndustryName=${IndustryName}`, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => {
      if(response.status==500) {
        console.log('500 no data')
        return null
      };
      if (!response.ok) {
        postRefreshToken().then((resToken) => {
          window.localStorage.setItem("accessToken", resToken.token);
          window.localStorage.setItem("refreshToken", resToken.refreshToken);
          // window.location.reload();
        });
      } else {
        return response.status === 204 ? "" : response.json();
      }
    })
    .catch((error) => console.error("Error:", error));
};


export const updateEsgLayout = (params, token) => {
  return fetch(`${apiEsg}/api/esglayout/update`, {
    method: "PUT",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
      "Accept-Language": "en-US",
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((response) => response) // no response
    .catch((error) => console.error("Error:", error));
};