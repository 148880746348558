var width = document.documentElement.clientWidth;

export const surveyJson301 = {
  title: "GRI 301：物料2016",
  logoPosition: "right",
  pages: [
    {
      name: "301-1",
      elements: [
        {
          type: "panel",
          name: "301-1-panel",
          elements: [
            {
              type: "text",
              name: "301-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "301-1 所有物料的重量或體積",
    },
    {
      name: "301-2",
      elements: [
        {
          type: "panel",
          name: "301-2-panel",
          elements: [
            {
              type: "text",
              name: "301-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "301-2 使用回收再利用的物料",
    },
    {
      name: "301-3",
      elements: [
        {
          type: "panel",
          name: "301-3-panel",
          elements: [
            {
              type: "text",
              name: "301-3",
              title: "指令關鍵字",
              description: "請輸入適當詞彙或完整句子",
            },
          ],
        },
      ],
      title: "301-3 回收的產品及其包材",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
