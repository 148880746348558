import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import { ContentCard1 } from "components/Content1.js";

function GriChat({ surveyJson, id }) {
  const [survey, setSurvey] = React.useState(null);
  const pathname = window.location.pathname.split("/")[1];
  const [page, setPage] = React.useState(`${pathname}-1`);

  useEffect(() => {
    pathname && setPage(`${pathname}-1`);
  }, [pathname]);

  useEffect(() => {
    setSurvey(new Model(surveyJson));
  }, [surveyJson]);

  useEffect(() => {
    if (survey) {
      survey.onValueChanged.add((result, options) => {
        console.log("urvey.onValueChanged ", options);
      });

      survey.onCurrentPageChanged.add(function (sender, options) {
        setPage(options.newCurrentPage.name);
      });
    }
  }, [survey, id]);

  return (
    <>
      <Box className="w-full flex-row flex">
        {survey ? <Survey model={survey} /> : <Box/>}

        <Box
          style={{
            width: "100%",
            background: "#f3f3f3",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <Box className="w-full h-[90px] bg-white bg-no-repeat bg-cover flex justify-between items-center" />
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
            (pageId, index) =>
              page === `${id}-${pageId}` && (
                <ContentCard1 survey={survey} id={`${id}-${pageId}`} />
              )
          )}
        </Box>
      </Box>
    </>
  );
}

export default GriChat;
