var width = document.documentElement.clientWidth;

export const surveyJson304 = {
  title: "GRI 304：生物多樣性",
  logoPosition: "right",
  pages: [
    {
      name: "304-1",
      elements: [
        {
          type: "panel",
          name: "304-1-panel",
          elements: [
            {
              type: "text",
              name: "304-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "304-1 組織所擁有、租賃、管理的營運據點或其鄰近地區位於環境保護區或其它高生物多樣性價值的地區",
    },
    {
      name: "304-2",
      elements: [
        {
          type: "panel",
          name: "304-2-panel",
          elements: [
            {
              type: "text",
              name: "304-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "304-2 活動、產品及服務，對生物多樣性方面的顯著衝擊",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
