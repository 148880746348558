import * as React from 'react';
import { Button, Menu, MenuItem } from "@mui/material";

export default function LongMenu({ name, options, navigate}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (page) => {
    setAnchorEl(null);
    if(typeof page !== 'string') return;
    navigate(`/${page}`);
  };

  return (
    <>
      <Button
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {name}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(option)}>
            GRI {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}