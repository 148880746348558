var width = document.documentElement.clientWidth;

export const surveyJson411 = {
  title: "GRI 411：原住民權力",
  logoPosition: "right",
  pages: [
    {
      name: "411-1",
      elements: [
        {
          type: "panel",
          name: "411-1-panel",
          elements: [
            {
              type: "text",
              name: "411-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "411-1 涉及侵害原住民權利的事件",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
