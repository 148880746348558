var width = document.documentElement.clientWidth;

export const surveyJson412 = {
  title: "GRI 412：人權評估",
  logoPosition: "right",
  pages: [
    {
      name: "412-1",
      elements: [
        {
          type: "panel",
          name: "412-1-panel",
          elements: [
            {
              type: "text",
              name: "412-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "412-1 接受人權檢視或人權衝擊評估的營運活動",
    },
    {
      name: "412-2",
      elements: [
        {
          type: "panel",
          name: "412-2-panel",
          elements: [
            {
              type: "text",
              name: "412-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "412-2 人權政策或程序的員工訓練",
    },
    {
      name: "412-3",
      elements: [
        {
          type: "panel",
          name: "412-3-panel",
          elements: [
            {
              type: "text",
              name: "412-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "412-3 載有人權條款或已進行人權審查的重要投資協議及合約",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
