import React, { useState, useEffect } from "react";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  Checkbox,
  IconButton,
  FormControlLabel,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { sendFilter } from "survey/chatKey.js";
import "./survey.css";

import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditNoteIcon from "@mui/icons-material/EditNote";

import { ReactComponent as ESvg } from "images/icon_esg_e.svg";
import { ReactComponent as SSvg } from "images/icon_esg_s.svg";
import { ReactComponent as GSvg } from "images/icon_esg_g.svg";

import { gri_data, key_data } from "data/food.js";

const grid = 4;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#19b394" : "#e7f7f4",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getCheeckedItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#19b394" : "#e7f7f4",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: "lightgrey",
  padding: grid,
  width: 500,
});

export function EsgIcon({ type, ...props }) {
  let IconComponent;
  switch (type) {
    case "E":
    case "e":
      IconComponent = ESvg;
      break;
    case "S":
    case "s":
      IconComponent = SSvg;
      break;
    case "G":
    case "g":
      IconComponent = GSvg;
      break;
    default:
      break;
  }
  return (
    <SvgIcon {...props}>
      <IconComponent />
    </SvgIcon>
  );
}

function Survey() {
  const accessToken = window.localStorage.getItem("accessToken") || "";

  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState(
    Array(gri_data.length).fill(false)
  );
  const [loading, setLoading] = useState(false);

  const curIndustry =
    useSelector((state) => state.app.curIndustry) ||
    localStorage.getItem("IndustryName");

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dataQuestions, setDataQuestions] = useState([]);
  const [curNum, setCurNum] = useState(10);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleDelete = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleCheck = (e, index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = e.target.checked;
    setCheckedItems(newCheckedItems);

    const newItems = gri_data.filter((item, idx) => newCheckedItems[idx]);
    setItems(newItems);
  };

  const handleCopyToClipboard = () => {
    let textToCopy = "";

    // const groupedData = items.reduce((acc, item) => {
    //   if (!acc[item.esg]) {
    //     acc[item.esg] = [];
    //   }
    //   acc[item.esg].push(item.name);
    //   return acc;
    // }, {});

    // for (const [esg, names] of Object.entries(groupedData)) {
    //   textToCopy += `${esg}:\n`;
    //   names.forEach((name) => {
    //     textToCopy += `${name}\n`;
    //   });
    //   textToCopy += "\n";
    // }

    const groupedQuestions = items.reduce((acc, item) => {
      const key = item.key;
      if (dataQuestions[key]) {
        if (!acc[item.esg]) {
          acc[item.esg] = [];
        }
        dataQuestions[key].forEach((question) => {
          acc[item.esg].push(`${question} (${key})`);
        });
      }
      return acc;
    }, {});

    const esgMapping = {
      G: "治理面",
      S: "社會面",
      E: "環境面"
    };

    for (const [esg, questions] of Object.entries(groupedQuestions)) {
      const esgText = esgMapping[esg] || '';
      textToCopy += `${esgText} ${esg}:\n`;
      questions.forEach((question) => {
        textToCopy += `${question}\n`;
      });
      textToCopy += "\n";
    }

    // items.forEach((item) => {
    //   const key = item.key;
    //   if (dataQuestions[key]) {
    //     textToCopy += `${key}:\n`;
    //     dataQuestions[key].forEach((question, idx) => {
    //       textToCopy += `${question} (${key})\n`;
    //     });
    //     textToCopy += "\n";
    //   }
    // });

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("已成功複製到剪貼簿");
      })
      .catch((err) => {
        console.error("複製到剪貼簿失敗:", err);
      });
  };

  const handleQuestionDelete = (key, index) => {
    setDataQuestions((prevDataQuestions) => {
      const updatedQuestions = prevDataQuestions[key].filter(
        (_, idx) => idx !== index
      );
      return {
        ...prevDataQuestions,
        [key]: updatedQuestions,
      };
    });
  };

  const handleFilter = () => {
    setLoading(true);
    if (gri_data && accessToken) {
      sendFilter(curNum, gri_data, curIndustry, (res) => {
        const arrIndexes = JSON.parse(res);
        if (arrIndexes) {
          setLoading(false);
          const newCheckedItems = gri_data.map((_, index) =>
            arrIndexes.includes(index)
          );

          setCheckedItems(newCheckedItems);

          const newItems = gri_data.filter((_, index) =>
            arrIndexes.includes(index)
          );
          setItems(prevItems => [...prevItems, ...newItems]);
        }
      });
    }
  };

  const handleNum = (e) => {
    const newValue = e.target.value;
    setCurNum(newValue);
  };

  useEffect(() => {
    const newCheckedItems = gri_data.map((item) => items.includes(item));
    setCheckedItems(newCheckedItems);
  }, [items]);

  useEffect(() => {
    setDataQuestions(key_data);
  }, [key_data]);

  return (
    <>
      <div className="flex justify-start">
        <div className="flex">
          <div className="ml-4">
            <DragDropContext>
              <Droppable droppableId="droppable" className="flex flex-wrap">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {gri_data.map((item, index) => {
                      return (
                        <Draggable
                          key={"gri" + index}
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={true}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    key={"checkbox" + index}
                                    checked={checkedItems[index]}
                                    onChange={(e) => handleCheck(e, index)}
                                  />
                                }
                                className="h-10"
                              />
                              <IconButton>
                                <EsgIcon type={item.esg} />
                              </IconButton>
                              {item.name}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="ml-2 flex flex-col">
            <select value={curNum} onChange={(e) => handleNum(e)} autoFocus>
              {Array.from({ length: 11 }, (_, i) => i + 10).map(
                (item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                )
              )}
            </select>
            <Tooltip title="篩選議題">
              <IconButton aria-label="selected" onClick={handleFilter}>
                <EditNoteIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="ml-2">
            {loading ? (
              <div className="typing">AI選擇重大議題中...</div>
            ) : (
              items.length > 0 && (
                <DragDropContext>
                  <Droppable
                    droppableId="droppable2"
                    className="flex flex-wrap"
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        className="w-[740px]"
                      >
                        {items.map((item, index) => {
                          return (
                            <>
                              <Draggable
                                key={"checked+" + index}
                                draggableId={"checked+" + index}
                                index={index}
                                isDragDisabled={true}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getCheeckedItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {item.name}
                                    {hoveredIndex === index && (
                                      <IconButton
                                        onClick={() => handleDelete(index)}
                                        style={{
                                          marginLeft: "auto",
                                          height: "24px",
                                        }}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                )}
                              </Draggable>

                              {dataQuestions[item.key] &&
                                (() => {
                                  return dataQuestions[item.key].map(
                                    (question, idx) => (
                                      <li
                                        key={idx}
                                        className="flex items-center justify-between p-2 border-b border-gray-200"
                                      >
                                        <span className="flex-grow">
                                          {question}
                                        </span>
                                        <IconButton
                                          onClick={() =>
                                            handleQuestionDelete(item.key, idx)
                                          }
                                          style={{
                                            marginLeft: "auto",
                                            height: "24px",
                                          }}
                                        >
                                          <ClearIcon />
                                        </IconButton>
                                      </li>
                                    )
                                  );
                                })()}
                            </>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )
            )}
          </div>
          {items.length > 5 && (
            <div className="ml-2">
              <Tooltip title="複製目錄">
                <IconButton aria-label="copy" onClick={handleCopyToClipboard}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Survey;
