import React, { useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";


import ChatButton from "components/ChatButton";
import CopyButton from "components/CopyButton";
import ContentCard from "components/ChatContentCard";

import { getEsg } from "api/esgApi";

export const ContentCardImg = ({ survey, id, type }) => {
  const navigate = useNavigate();
  const [content, setContent] = React.useState("");
  const [seqNo, setSeqNo] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [content_title, setContent_title] = React.useState("");

  const handleChat = (event) => {

    window.location.reload();

  };

  const accessToken = window.localStorage.getItem("accessToken") || "";

  useEffect(() => {
    if (accessToken === "undefined") {
      window.localStorage.removeItem("accessToken");
      navigate("/");
      return;
    }

    if (accessToken) {
      setContent("");

      getEsg(id, accessToken).then((res) => {
        setLoading(false);
        console.log("getEsg", id, res);

        if (!res) {
          setContent("");
          setSeqNo(0);
          return;
        }

        if (res && res.seqNo !== 0) {
          setContent_title(res.title);
          setContent(res.content);
          setSeqNo(res.seqNo);
        }
      });
    }
  }, [accessToken, id, navigate]);

  if (content === "") {
    return (
      <>
        {loading && (
          <div className="relative top-[-1.3px]">
            <LinearProgress color="success" className="h-[1.5px]" />
          </div>
        )}
        <ChatButton handleChat={handleChat} />
      </>
    );
  }

  return (
    <>
      <Box className="h-[106px]">
        <ChatButton handleChat={handleChat} />
        <CopyButton text={`${content}`} />
      </Box>
      <Box className="mr-4">
         <ContentCard
          title={content_title}
          content={content}
          content_file={''}
          content_from={''}
          seqNo={seqNo}
          templateId={id}
          type={type}
        />

      </Box>
    </>
  );
};
