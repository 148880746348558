import React from "react";
import { AppBar, Link } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LongMenu from "components/LongMenu";


const industry = localStorage.getItem('IndustryName');
const NavLink = ({ href, name, newTab }) => {
  const location = useLocation();

  return (
    <Link
      variant="button"
      color="text.primary"
      href={href}
      className="mx-1.5"
      underline={location.pathname === href ? "always" : "none"}
      target={newTab ? "_blank" : "_self"}
      rel={newTab ? "noopener noreferrer" : ""}
    >
      {name}
    </Link>
  );
};

const opts200 = [
  '201',
  '202',
  '203',
  '204',
  '205',
  '206',
];
if(industry !== '食品業') opts200.push('207');


const opts300 = [
  '301',
  '302',
  '303',
  '305',
  '306',
  '307',
  '308',
];
if(industry !== '食品業') {
  const index = opts300.indexOf('303');
  if (index !== -1) {
    opts300.splice(index + 1, 0, '304');
  }
}

const opts400 = [
  '401',
  '403',
  '404',
  '405',
  '409',
  '413',
  '414',
  '416',
  '417',
  '418',
  '419',
];

if(industry !== '食品業') {
  const index = opts400.indexOf('401');
  if (index !== -1) {
    opts400.splice(index + 1, 0, '402');
    opts400.splice(index + 5, 0, '406');
    opts400.splice(index + 6, 0, '407');
    opts400.splice(index + 7, 0, '408');
    opts400.splice(index + 8, 0, '409');
    opts400.splice(index + 9, 0, '411');
    opts400.splice(index + 10, 0, '412');
  }
}

const Bar = () => {
  const curIndustry = useSelector((state) => state.app.curIndustry) || localStorage.getItem('IndustryName');
 
  const navigate = useNavigate();

  return (
    <AppBar
      elevation={0}
      className=" h-8 flex-row bg-white items-center w-full"
    >
      <nav>
        <Link href="#" underline="none" color="#19b394" className="ml-1 items-center"><b>{curIndustry}</b> |</Link>
        <NavLink href="/" name="回選單" />
        <NavLink href="/2" name="GRI 2" />
        <NavLink href="/3" name="GRI 3" />
        <LongMenu name="GRI 200" options={opts200} navigate={navigate} />
        <LongMenu name="GRI 300" options={opts300} navigate={navigate} />
        <LongMenu name="GRI 400" options={opts400} navigate={navigate} />
        <NavLink href="/sasb" name="sasb" />
        <NavLink href="/examples" name="目錄" />
        <NavLink href="/survey" name="重大議題篩選" />
        { curIndustry=== '金融業' && <NavLink href="/4" name="G4" /> }
        {/* <LongMenu name="圖片生成" options={optsImage} navigate={navigate} />
        <NavLink href="/menu" name="目錄編輯" />
        <NavLink href="https://paper.nuwo.ai" name="報告書生成展示" newTab={true}/> */}
      </nav>
    </AppBar>
  );
};

export default Bar;
