var width = document.documentElement.clientWidth;

export const surveyJson303 = {
  title: "GRI 303：水與放流水2018",
  logoPosition: "right",
  pages: [
    {
      name: "303-1",
      elements: [
        {
          type: "panel",
          name: "303-1-panel",
          elements: [
            {
              type: "text",
              name: "303-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "303-1 共享水資源之相互影響",
    },
    {
      name: "303-2",
      elements: [
        {
          type: "panel",
          name: "303-2-panel",
          elements: [
            {
              type: "text",
              name: "303-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "303-2 與排水相關衝擊的管理",
    },
    {
      name: "303-3",
      elements: [
        {
          type: "panel",
          name: "303-3-panel",
          elements: [
            {
              type: "text",
              name: "303-3",
              title: "取水量",
            },
          ],
        },
      ],
      title: "303-3 取水量",
    },
    {
      name: "303-4",
      elements: [
        {
          type: "panel",
          name: "303-4-panel",
          elements: [
            {
              type: "text",
              name: "303-4",
              title: "排水量",
            },
          ],
        },
      ],
      title: "303-4 排水量",
    },
    {
      name: "303-5",
      elements: [
        {
          type: "panel",
          name: "303-5-panel",
          elements: [
            {
              type: "text",
              name: "303-5",
              title: "耗水量",
            },
          ],
        },
      ],
      title: "303-5 耗水量",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  // completeText: "Submit",
  widthMode: "static",
  width: width,
};
