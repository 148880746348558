import React, { useState, useEffect } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { getEsgReport } from "api/esgApi";
import { Box } from "@mui/material";

const grid = 4;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#19b394" : "#e7f7f4",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "darkgrey" : "lightgrey",
  padding: grid,
  width: 500,
});

function DndEx() {
  const accessToken = window.localStorage.getItem("accessToken") || "";
  const [items, setItems] = useState(["A"]);

  const [content, setContent] = useState("");

  const curIndustry =
    useSelector((state) => state.app.curIndustry) ||
    localStorage.getItem("IndustryName");

  useEffect(() => {
    if (accessToken) {
      getEsgReport(accessToken, curIndustry).then((res) => {
        setItems(res);
      });
    }
  }, [accessToken]);

  return (
    <>
      <div className="flex justify-start">
        <div className="ml-2 flex flex-col fixed">
        </div>
        <DragDropContext>
          <Droppable droppableId="droppable" className="flex flex-wrap">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items && items.map((item, index) => {
                  if (!item.griDetailName) {
                    return null;
                  }
                  return (
                    <Draggable
                      key={item.griDetailName}
                      draggableId={item.griDetailName}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          onClick={() => {
                            console.log("Clicked item index:", index, items);
                            setContent(items[index]);
                          }}
                        >
                          {item.griDetailName}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Box className="fixed top-[50px] right-[300px] ml-2 max-w-sm ">
          <div className="overflow-auto  max-h-[500px]">
            {content.griDetailName}
            <br />
            <br />
            <MarkdownPreview source={content.content} className="mt-2"/>
          </div>
        </Box>
      </div>
    </>
  );
}

export default DndEx;
