import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Link,
  Typography,
} from "@mui/material";
import ChatButton from "components/ChatButton";
import CopyButton from "components/CopyButton";

import { Typewriter } from "react-typewriting-effect";
import "react-typewriting-effect/dist/index.css";
import { getTemplate, addEsg, updateEsg, getEsg } from "api/esgApi.js";
import SaveButton from "./SaveButton";


export const ContentCard2_3 = ({ survey, title }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [seqNo, setSeqNo] = React.useState(0);
  const [content, setContent] = React.useState("");
  const [templateId, setTemplateId] = React.useState("");
  const [content_title, setContent_title] = React.useState("");
  const [content_from, setContent_from] = React.useState("");
  const [content_file, setContent_file] = React.useState("");
  const [content_companyInfo, setContent_companyInfo] = React.useState("");
  const [conpamy, setConpamy] = React.useState("");
  const accessToken = window.localStorage.getItem("accessToken") || "";
  const id = "2-3";


const send2_3 = (
  value,
  setContent,
  setContent_from,
  setContent_file
) => {
  const chatMsg2_3 = [
    { 
        "content" : "報告期間為民國 Y 年 1 月 1 日至民國 Y 年 12 月 31 日。C民國 N 年 M 月 於ESG 網站發行中／英文版本報告書，內容涵蓋利害關係人關注的各項重大議題，以及C在經濟、環境、社會及治理等面向的具體實績。",
        "from": "資料格式來源: 台積電2022年",
        "file": "https://file.numiner.io/A01.pdf#page=217"
    },
    { 
        "content" : "本報告資訊揭露期間為Y年1月1日至2022年12月31日，前一本報告書的發行日期為Y年M月，每年定期於網站發行報告書，作為持續落實企業永續發展之重要使命。",
        "from": "資料格式來源: 聯發科2022年",
        "file": "https://file.numiner.io/A02.pdf#page=148"
    },
    { 
        "content" : "本報告報導期間為Y年1月1日至Y年12月31日，與財務報告報導期間相同。我們每年M月出版永續報告書。",
        "from": "資料格式來源: 日月光2022年",
        "file": "https://file.numiner.io/A03.pdf#page=200"
    }
];
  const randomNumber = Math.floor(Math.random() * 5);

  if (randomNumber === 0) {
    value.Y = String(parseInt(value["Y"]) - 1911);
    value.N = String(parseInt(value["N"]) - 1911);
  }
  new Promise((resolve) => {
    const replacementMap = value;
    const replacedText = chatMsg2_3[randomNumber].content.replace(
      /([YMNLC][12]?)|\(([^)]+)\)/g,
      (match, group1, group2) => {
        return group1 ? replacementMap[group1] : `(${replacementMap[group2]})`;
      }
    );
    setContent("");
    resolve(replacedText);
  }).then((replacedText) => {
    setContent(replacedText);
  });

  setContent_from(chatMsg2_3[randomNumber].from);
  setContent_file(chatMsg2_3[randomNumber].file);
};

  const handleSave = () => {
    const accessToken = window.localStorage.getItem("accessToken") || "";
    if (seqNo !== 0 && accessToken && content !== "") {
      updateEsg(
        {
          content: content,
          seqNo: seqNo,
        },
        accessToken
      ).then((res) => {
        console.log("updateEsg:", res);
      });
    } else if (seqNo == 0 && accessToken && content !== "") {
      addEsg(
        {
          content: content,
          esgTemplateSeqNo: templateId,
        },
        accessToken
      ).then((res) => {
        console.log("addEsg:", res);
      });
    }
  };

  const handleChat = (event) => {

    const year = survey.getQuestionByName("2-3-a-dropdown").value;
    const month = survey.getQuestionByName("2-3-b-dropdown").value;

    const name = survey.getQuestionByName("2-1-a").value;
    const company = survey.getQuestionByName("2-1-a-dropdown").value;
    const conpamy_name = name + company;
    setConpamy(conpamy_name);

    if (!year || !month) {
      setContent("");
      return;
    }

    const ask = {
      Y: year,
      M: month,
      N: parseInt(year) + 1,
      L: parseInt(year) + 2,
      C: conpamy_name,
    };


    if (accessToken) {
      getTemplate('2-3', accessToken).then((res) => {
        console.log("getTemplate:",  res);
        // setContent_title(res.title);
        setContent_from(res.from);
        setContent_file(res.file);
        setTemplateId(res.seqNo);

        send2_3(ask, setContent, setContent_from, setContent_file);
      });
    }

 

    const establishment = survey.getQuestionByName("2-3-b").value;
    const info = survey.getQuestionByName("2-3-c").value;
    const capitalization = survey.getQuestionByName("2-3-d").value;
    const contact = survey.getQuestionByName("2-3-e").value;
    const address = survey.getQuestionByName("2-3-f").value;
    const phone = survey.getQuestionByName("2-3-g").value;
    const email = survey.getQuestionByName("2-3-h").value;
    const website = survey.getQuestionByName("2-3-i").value;

    const companyInfo = {
      establishment: establishment,
      info: info,
      capitalization: capitalization,
      contact: contact,
      address: address,
      phone: phone,
      email: email,
      website: website,
    };

    const companyInfoKey = {
      establishment: "成立日期",
      info: "上市資訊",
      capitalization: "資本額",
      contact: "聯絡單位",
      address: "地址",
      phone: "電話",
      email: "信箱",
      website: "網站",
    };

    let companyInfoContent = "";
    Object.entries(companyInfo).forEach(([key, value]) => {
      console.log(333333, key, value);
      if (value) {
        companyInfoContent += `${companyInfoKey[key]}: ${value} \n`;
      }
    });
    setContent_companyInfo(companyInfoContent);
  };

  useEffect(() => {
    if (accessToken === "undefined") {
      window.localStorage.removeItem("accessToken");
      navigate("/");
      return;
    }

    if (accessToken) {
      setContent("");
      
      let templateId =  id;

      getEsg(templateId, accessToken).then((res) => {
        setLoading(false);
        console.log("getEsg", id, res);

        if (!res) {
          setContent("");
          setSeqNo(0);
          return;
        }

        if (res && res.seqNo !== 0) {
          setContent_title(res.title);
          setContent(res.content);
          setSeqNo(res.seqNo);
        }
      });
    }
  }, [accessToken, id]);

  if (content === "") {
    return <ChatButton handleChat={handleChat} />;
  }

  return (
    <>
      <Box className="h-[106px]">
        <ChatButton handleChat={handleChat} />
        <CopyButton
          text={`${content}\n\n${conpamy}\n\n${content_companyInfo}`}
        />
      </Box>
      <Box style={{ marginRight: 16 }}>
        <Card>
          <CardContent>
            <Typography className="mb-4 text-lg font-bold">{title}</Typography>

            <Typography className="text-black text-lg">
              <Typewriter string={content} delay={50} />
            </Typography>
          </CardContent>

          <CardActions>
            <Typography className="ml-2">
              <Link
                href={content_file}
                target="_blank"
                rel="noopener noreferrer"
              >
                {content_from}
              </Link>
            </Typography>
            {templateId && <SaveButton handleSave={handleSave} />}
          </CardActions>
        </Card>
        { conpamy && <Card className="mt-4">
          <CardContent>
            <Typography className="mb-4 text-lg font-bold">
              {conpamy || "公司名稱"}
            </Typography>

            <Typography
              className="text-black text-lg"
              style={{ whiteSpace: "pre-line" }}
            >
              {content_companyInfo}
            </Typography>
          </CardContent>
        </Card> }
      </Box>
    </>
  );
};
