import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { getEsgLayout, updateEsgLayout } from "api/esgApi";
import { Box, IconButton, Tooltip } from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ReplayIcon from "@mui/icons-material/Replay";

import { CopyToClipboard } from "react-copy-to-clipboard";

const grid = 4;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#19b394" : "#e7f7f4",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "darkgrey" : "lightgrey",
  padding: grid,
  width: 500,
});

function Dnd() {
  const accessToken = window.localStorage.getItem("accessToken") || "";
  const [items, setItems] = useState(["A"]);
  const [defaultItems, setDefaultItems] = useState([]);
  const [content, setContent] = useState("");
  const [catalog, setCatalog] = useState("");
  const [seqNo, setSeqNo] = useState(0);

  const openModal = () => {
    window.open("/copy", "_blank");
  };

  const onDragEnd = (event) => {
    const { source, destination } = event;

    if (!destination) {
      return;
    }

    // 拷貝新的items (來自state)
    let newItems = [...items];

    // splice(start, deleteCount, item )
    // 從source.index剪下被拖曳的元素
    const [remove] = newItems.splice(source.index, 1);

    //在destination.index位置貼上被拖曳的元素
    newItems.splice(destination.index, 0, remove);

    let layoutArray = newItems.map((item) => item.code);

    // 設定新的 items
    setItems(newItems);

    if (seqNo !== 0 && accessToken && layoutArray.length > 0) {
      updateEsgLayout(
        {
          layoutArray: layoutArray,
          seqNo: seqNo,
        },
        accessToken
      ).then((res) => {
        console.log("updateEsgLayout:", res);
      });
    }
  };

  useEffect(() => {
    if (accessToken) {
      getEsgLayout(accessToken).then((res) => {
        if (!res) {
          console.log("Response is undefined");
          return;
        }

        setSeqNo(res.seqNo);

        const filterItems = res.layoutArray.filter(
          (item) => item.content !== ""
        );
        const names = filterItems.map((item) =>
          item.name.replace(item.code, "").trim()
        );
        const namesString = names.join("\n");
        setCatalog(namesString);
        setItems(res.layoutArray);
        setDefaultItems(res.layoutArrayByDefault);
      });
    }
  }, [accessToken]);

  return (
    <>
      <div className="flex justify-start">
        <div className="ml-2">
          <Tooltip title="恢復預設排序">
            <IconButton
              aria-label="copy"
              onClick={() => {
                if (window.confirm("確定要恢復預設嗎？")) {
                  setItems(defaultItems);
                }
              }}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" className="flex flex-wrap">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => {
                  if (!item.name) {
                    return null;
                  }
                  const parts = item.name.split("-");
                  const link = parts[0];
                  const rest = parts[1];
                  return (
                    <Draggable
                      key={item.code}
                      draggableId={item.code}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          onClick={() => {
                            console.log("Clicked item index:", index, items);
                            setContent(items[index]);
                          }}
                        >
                          <a href={`/${link}`} rel="noopener noreferrer">
                            {link}
                          </a>
                          -{rest}{" "}
                          {item.content && item.content !== "" ? "✔" : ""}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Box className="fixed top-[50px] right-[300px] ml-2 max-w-sm ">
          <div className="overflow-auto  max-h-[500px]">
            {content.name}
            <br />
            <br />
            <ReactMarkdown>{content.content}</ReactMarkdown>
          </div>
        </Box>
        <div className="relative">
          <div className="absolute -right-10 z-100 top-0">
            <CopyToClipboard text={catalog}>
              <Tooltip title="複製目錄">
                <IconButton aria-label="copy">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </div>
          <div className="absolute -right-20 z-100 top-0">
            <Tooltip title="複製全文">
              <IconButton aria-label="copy" title="Copy" onClick={openModal}>
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dnd;
