var width = document.documentElement.clientWidth;

export const surveyJson403 = {
  title: "GRI 403：職業安全衛生 2018",
  logoPosition: "right",
  pages: [
    {
      name: "403-1",
      elements: [
        {
          type: "panel",
          name: "403-1-panel",
          elements: [
            {
              type: "text",
              name: "403-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-1 職業安全衛生管理系統",
    },
    {
      name: "403-2",
      elements: [
        {
          type: "panel",
          name: "403-2-panel",
          elements: [
            {
              type: "text",
              name: "403-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-2 危害辨識、風險評估、及事故調查",
    },
    {
      name: "403-3",
      elements: [
        {
          type: "panel",
          name: "403-3-panel",
          elements: [
            {
              type: "text",
              name: "403-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-3 職業健康服務",
    },
    {
      name: "403-4",
      elements: [
        {
          type: "panel",
          name: "403-4-panel",
          elements: [
            {
              type: "text",
              name: "403-4",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-4 有關職業安全衛生之工作者參與、諮商與溝通",
    },
    {
      name: "403-5",
      elements: [
        {
          type: "panel",
          name: "403-5-panel",
          elements: [
            {
              type: "text",
              name: "403-5",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-5 有關職業安全衛生之工作者訓練",
    },
    {
      name: "403-6",
      elements: [
        {
          type: "panel",
          name: "403-6-panel",
          elements: [
            {
              type: "text",
              name: "403-6",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-6 工作者健康促進",
    },
    {
      name: "403-7",
      elements: [
        {
          type: "panel",
          name: "403-7-panel",
          elements: [
            {
              type: "text",
              name: "403-7",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-7 預防和減輕與業務關係直接相關聯之職業安全衛生的衝擊",
    },
    {
      name: "403-8",
      elements: [
        {
          type: "panel",
          name: "403-8-panel",
          elements: [
            {
              type: "text",
              name: "403-8",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-8 職業安全衛生管理系統所涵蓋之工作者",
    },
    {
      name: "403-9",
      elements: [
        {
          type: "panel",
          name: "403-9-panel",
          elements: [
            {
              type: "text",
              name: "403-9",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-9 職業傷害",
    },
    {
      name: "403-10",
      elements: [
        {
          type: "panel",
          name: "403-10-panel",
          elements: [
            {
              type: "text",
              name: "403-10",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "403-10 職業病",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
