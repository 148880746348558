import { IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyButton = ({ text }) => (
  <CopyToClipboard text={text} className="top-11">
    <IconButton aria-label="copy">
      <FileCopyIcon />
    </IconButton>
  </CopyToClipboard>
);

export default CopyButton;
