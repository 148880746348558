import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import { getEsgLayout } from "api/esgApi";
import { Box } from "@mui/material";

// function WordCount({ count }) {
//   return (
//     <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
//       字數: {count}
//     </div>
//   );
// }
function DndCopy() {
  const accessToken = window.localStorage.getItem("accessToken") || "";
  const [items, setItems] = useState(["A"]);
  // const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    if (accessToken) {
      getEsgLayout(accessToken).then((res) => {
        setItems(res.layoutArray);

        // 計算字數
        let count = 0;
        for (let item of res.layoutArray) {
          if (item.content) {
            count += item.content.split(" ").length;
          }
        }
        // setWordCount(count);
      });
    }
  }, [accessToken]);
  let total = 0;
  let milestone = 7500;
  return (
    <>
      <Box className="bg-white p-4">
        {items.map(
          (item, index) => {
            if(!item.content) return null;
            total += (item.name ? item.name.length : 0) + (item.content ? item.content.length : 0);
    
            if (total >= milestone) {
              milestone += 7500;
              return (
                <div key={item.code} style={{ position: 'relative'}}>
                  <div className="text-[#F00]">{item.name}</div>
                  <br />
                  {item.content}
                  <br />
                  <br />
                </div>
              );
            }
            return (
              <div key={item.code} style={{ position: 'relative' }}>
                {item.name}
                <br />
                <br />
                {/* {item.content} */}
                <ReactMarkdown>{item.content}</ReactMarkdown>
                <br />
                <br />
              </div>
            );
          }
        )}
      </Box>
    </>
  );
}

export default DndCopy;
