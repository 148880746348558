var width = document.documentElement.clientWidth;

export const surveyJson405 = {
  title: "GRI 405：員工多元化與平等機會 2016",
  logoPosition: "right",
  pages: [
    {
      name: "405-1",
      elements: [
        {
          type: "panel",
          name: "405-1-panel",
          elements: [
            {
              type: "text",
              name: "405-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "405-1 治理單位與員工的多元化",
    },
    {
      name: "405-2",
      elements: [
        {
          type: "panel",
          name: "405-2-panel",
          elements: [
            {
              type: "text",
              name: "405-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "405-2 女性對男性基本薪資加薪酬的比率",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
