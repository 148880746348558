var width = document.documentElement.clientWidth;

export const surveyJson308 = {
  title: "GRI 308：廢棄物 2020",
  logoPosition: "right",
  pages: [
    {
      name: "308-1",
      elements: [
        {
          type: "panel",
          name: "308-1-panel",
          elements: [
            {
              type: "text",
              name: "308-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "308-1 廢棄物的產生與廢棄物相關顯著衝擊",
    },
    {
      name: "308-2",
      elements: [
        {
          type: "panel",
          name: "308-2-panel",
          elements: [
            {
              type: "text",
              name: "308-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "308-2 廢棄物相關顯著衝擊之管理",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
