import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getEsgReport } from "api/esgApi";

import { Box } from "@mui/material";

function DndSasb() {
  const accessToken = window.localStorage.getItem("accessToken") || "";
  const [items, setItems] = useState(["A"]);
  const [groupTitle, setGroupTitle] = useState({});

  const curIndustry =
    useSelector((state) => state.app.curIndustry) ||
    localStorage.getItem("IndustryName");

  useEffect(() => {
    if (accessToken) {
      getEsgReport(accessToken, curIndustry+'sasb').then((res) => {
        if(res) setItems(res);
      });
    }
  }, [accessToken]);

  let currentColor = "#f2f2f2";
  let currentArticleColor = "#f2f2f2";

  return (
    <>
      <Box className="bg-white p-4">
        <span className="text-[#7293a1] text-xl">永續會計準則委員會 (SASB) 內容索引表</span>
        <table className="mt-2">
          <thead>
            <tr>
              <th className="bg-[#7293a1]">揭露主題</th>
              <th className="w-[480px] bg-[#7293a1]">指標編號</th>
              <th className="text-left bg-[#7293a1]">揭露指標</th>
              <th className="bg-[#7293a1]">數據</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              // console.log(item, index);
              if (!item.content) return null;

              // 揭露主題
              let showLevel2 =
                index === 0 || item.griName !== items[index - 1].griName;
              // 指標編號
              let showLevel3 =
                index === 0 || item.griDetailCode !== items[index - 1].griDetailCode;
              // 揭露指標
              let showArticle =
                index === 0 || item.griDetailName !== items[index - 1].griDetailName;

              if (showLevel2) {
                currentColor =
                  currentColor === "#f2f2f2" ? "#e9e9e9" : "#f2f2f2";
              }
              if (showArticle) {
                currentArticleColor =
                  currentArticleColor === "#f2f2f2" ? "#e9e9e9" : "#f2f2f2";
              }

              return (
                <>
                  {item.recog in groupTitle && (
                    <tr>
                      <td className="text-center bg-[#d0d0d0]"></td>
                      <td className="text-center bg-[#d0d0d0]">
                        {item.gri_title}
                      </td>
                      <td className="text-center bg-[#d0d0d0]"></td>
                      <td className="text-center bg-[#d0d0d0]"></td>
                    </tr>
                  )}
                  <tr key={item.recog}>
                    <td className={`text-left bg-[${currentColor}]`}>
                      {showLevel2 ? item.griName : ""}
                    </td>
                    <td
                      className={`text-left w-[480px] bg-[${currentArticleColor}]`}
                    >
                      {showLevel3 ? item.griDetailCode : ""}
                    </td>
                    <td
                      className={`text-left w-12 ${
                        index % 2 === 0 ? "bg-[#e9e9e9]" : "bg-[#f2f2f2]"
                      }`}
                    >
                      {showArticle ? item.griDetailName : ""}
                    </td>
                    <td
                      className={`text-left ${
                        index % 2 === 0 ? "bg-[#e9e9e9]" : "bg-[#f2f2f2]"
                      }`}
                    >
                      {item.content}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </Box>
    </>
  );
}

export default DndSasb;
