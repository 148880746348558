import { createPage } from './createPage';

var width = document.documentElement.clientWidth;

export const surveyJson3 = {
  title: "GRI 3：重大主題",
  logoPosition: "right",
  pages: [
    createPage("3-1", "3-1 決定重大主題的流程"),
    createPage("3-2", "3-2 重大主題列表"),
    createPage("3-3", "3-3 重大主題管理"),
    createPage("3-3-1", "3-3 重大主題管理-法規遵循"),
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
