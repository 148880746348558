var width = document.documentElement.clientWidth;

export const surveyJson205 = {
  title: "GRI 205：反貪腐 2016",
  logoPosition: "right",
  pages: [
    {
      name: "205-1",
      elements: [
        {
          type: "panel",
          name: "205-1-panel",
          elements: [
            {
              type: "text",
              name: "205-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "205-1 已進⾏貪腐風險評估的營運據點",
    },
    {
      name: "205-2",
      elements: [
        {
          type: "panel",
          name: "205-2-panel",
          elements: [
            {
              type: "text",
              name: "205-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "205-2 有關反貪腐政策和程序的溝通及訓練",
    },
    {
      name: "205-3",
      elements: [
        {
          type: "panel",
          name: "205-3-panel",
          elements: [
            {
              type: "text",
              name: "205-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "205-3 已確認的貪腐事件及採取的⾏動",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
