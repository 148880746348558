export function createPage(name, title) {
  return {
    name,
    elements: [
      {
        type: "panel",
        name: `${name}-panel`,
        elements: [
          {
            type: "comment",
            name,
            title: "指令關鍵字",
            description: "請輸入適當詞彙或完整句子",
            rows: 1,
          },
        ],
      },
    ],
    title,
  };
}
