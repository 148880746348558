import React, { useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { send } from "survey/chatKey.js";
import ChatButton from "components/ChatButton";
import CopyButton from "components/CopyButton";
import ContentCardChart from "components/ChatContentChart";
import { useSetShowWrite } from 'state/app/hooks';
import { getTemplate, getEsg } from "api/esgApi";

export const ContentChart = ({ survey, id, type }) => {
  const navigate = useNavigate();
  const [content, setContent] = React.useState("");
  const [seqNo, setSeqNo] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [content_title, setContent_title] = React.useState("");
  const [content_from, setContent_from] = React.useState("");
  const [content_file, setContent_file] = React.useState("");
  const [templateId, setTemplateId] = React.useState("");
  const setShowWrite = useSetShowWrite();

  const handleChat = (event) => {
    const ask = survey.getQuestionByName(id).value || "";

    if (accessToken) {
      getTemplate(id, accessToken).then((res) => {
        setContent_title(res.title);
        setContent_from(res.from);
        setContent_file(res.file);
        setTemplateId(res.seqNo);

        send(ask, setContent, res, setShowWrite);
      });
    }
  };

  const accessToken = window.localStorage.getItem("accessToken") || "";

  useEffect(() => {
    if (accessToken === "undefined") {
      window.localStorage.removeItem("accessToken");
      navigate("/");
      return;
    }

    if (accessToken) {
      setContent("");
      getEsg(id, accessToken).then((res) => {
        setLoading(false);

        if (!res) {
          setContent("");
          setSeqNo(0);
          return;
        }

        if (res && res.seqNo !== 0) {
          setContent_title(res.title);
          setContent(res.content);
          setSeqNo(res.seqNo);
        }
      });
    }
  }, [accessToken, id, navigate]);

  if (content === "") {
    return (
      <>
        {loading && (
          <div className="relative top-[-1.3px]">
            <LinearProgress color="success" className="h-[1.5px]" />
          </div>
        )}
        <ChatButton handleChat={handleChat} />
      </>
    );
  }

  return (
    <>
      <Box className="h-[106px]">
        <ChatButton handleChat={handleChat} />
        <CopyButton text={`${content}`} />
      </Box>
      <Box className="mr-4">
         <ContentCardChart
          title={content_title}
          content={content}
          content_file={content_file}
          content_from={content_from}
          seqNo={seqNo}
          templateId={templateId}
          type={type}
        />
      </Box>
    </>
  );
};
