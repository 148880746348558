import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Dnd from "./Dnd";
import DndCopy from "./DndCopy";
import DndEx from "./DndEx";
import Survey from "./Survey";
import DndSasb from "./DndSasb";

import Layout from "./Layout";
import GriChat from './pages/GriChat';
import GriOne from './pages/GriOne';
import Gri303 from './pages/Gri303';
import GriImage from './pages/GriImage';
import GriImage2 from './pages/GriImage2';

import { surveyJson3 } from "survey/3.js";
import { surveyJson4 } from "survey/4.js";

import { surveyJson201 } from "survey/200/201.js";
import { surveyJson202 } from "survey/200/202.js";
import { survey202food } from "survey/200/202food.js";
import { surveyJson203 } from "survey/200/203.js";
import { survey203food } from "survey/200/203food.js";
import { surveyJson204 } from "survey/200/204.js";
import { surveyJson205 } from "survey/200/205.js";
import { surveyJson206 } from "survey/200/206.js";
import { surveyJson207 } from "survey/200/207.js";
import { surveyJson301 } from "survey/300/301.js";
import { survey301food } from "survey/300/301food.js";
import { surveyJson302 } from "survey/300/302.js";
import { surveyJson303 } from "survey/300/303.js";
import { surveyJson304 } from "survey/300/304.js";
import { surveyJson305 } from "survey/300/305.js";
import { surveyJson306 } from "survey/300/306.js";
import { surveyJson307 } from "survey/300/307.js";
import { surveyJson308 } from "survey/300/308.js";

import { surveyJson401 } from "survey/400/401.js";
import { surveyJson402 } from "survey/400/402.js";
import { survey401food } from "survey/400/401food.js";
import { surveyJson403 } from "survey/400/403.js";
import { surveyJson404 } from "survey/400/404.js";
import { surveyJson405 } from "survey/400/405.js";
import { surveyJson406 } from "survey/400/406.js";
import { surveyJson407 } from "survey/400/407.js";
import { surveyJson408 } from "survey/400/408.js";
import { surveyJson409 } from "survey/400/409.js";
import { surveyJson411 } from "survey/400/411.js";
import { surveyJson412 } from "survey/400/412.js";
import { surveyJson413 } from "survey/400/413.js";
import { survey413food } from "survey/400/413food.js";
import { surveyJson414 } from "survey/400/414.js";
import { surveyJson416 } from "survey/400/416.js";
import { surveyJson417 } from "survey/400/417.js";
import { survey417food } from "survey/400/417food.js";
import { surveyJson418 } from "survey/400/418.js";
import { surveyJson419 } from "survey/400/419.js";

const industry = localStorage.getItem('IndustryName');
const router = createBrowserRouter([
  {
    path: "/copy",
    element: <DndCopy />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <App /> },
      { path: "/2", element: <App /> },
      { path: "/menu", element: <Dnd /> },
      { path: "/examples", element: <DndEx /> },
      { path: "/survey", element: <Survey /> },
      { path: "/sasb", element: <DndSasb /> },
      { path: "/2-1", element: <GriImage /> },
      { path: "/2-11", element: <GriImage2 /> },
      { path: "/3", element: <GriChat surveyJson={surveyJson3} id="3"/> },
      { path: "/201", element: <GriChat surveyJson={surveyJson201} id="201" /> },
      { path: "/202", element: industry === '食品業' ? <GriOne surveyJson={survey202food} id="202-2" /> : <GriChat surveyJson={surveyJson202} id="202"/> },
      { path: "/203", element: industry === '食品業' ? <GriOne surveyJson={survey203food} id="203-1" /> : <GriChat surveyJson={surveyJson203} id="203"/> },
      { path: "/204", element: <GriOne surveyJson={surveyJson204} id="204-1" /> },
      { path: "/205", element: <GriChat surveyJson={surveyJson205} id="205" /> },
      { path: "/206", element: <GriOne surveyJson={surveyJson206} id="206-1" /> },
      { path: "/207", element: <GriChat surveyJson={surveyJson207} id="207" /> },
      { path: "/301", element: industry === '食品業' ? <GriOne surveyJson={survey301food} id="301-1" /> : <GriChat surveyJson={surveyJson301} id="301"/> },
      { path: "/302", element: <GriChat surveyJson={surveyJson302} id="302"/> },
      { path: "/303", element: <Gri303 surveyJson={surveyJson303} id="303"/> },
      { path: "/304", element: <GriChat surveyJson={surveyJson304} id="304" /> },
      { path: "/305", element: <GriChat surveyJson={surveyJson305} id="305"/> },
      { path: "/306", element: <GriChat surveyJson={surveyJson306} id="306"/> },
      { path: "/307", element: <GriChat surveyJson={surveyJson307} id="307"/> },
      { path: "/308", element: <GriChat surveyJson={surveyJson308} id="308"/> },
      { path: "/401", element: industry === '食品業' ? <GriChat surveyJson={survey401food} id="401"/> :<GriChat surveyJson={surveyJson401} id="401"/> },
      { path: "/402", element: <GriChat surveyJson={surveyJson402} id="402"/> },
      { path: "/403", element: <GriChat surveyJson={surveyJson403} id="403"/> },
      { path: "/404", element: <GriChat surveyJson={surveyJson404} id="404"/> },
      { path: "/405", element: <GriChat surveyJson={surveyJson405} id="405"/> },
      { path: "/406", element: <GriChat surveyJson={surveyJson406} id="406"/> },
      { path: "/407", element: <GriChat surveyJson={surveyJson407} id="407"/> },
      { path: "/408", element: <GriChat surveyJson={surveyJson408} id="408"/> },
      { path: "/409", element: <GriChat surveyJson={surveyJson409} id="409"/> },
      { path: "/411", element: <GriChat surveyJson={surveyJson411} id="411"/> },
      { path: "/412", element: <GriChat surveyJson={surveyJson412} id="412"/> },
      { path: "/413", element: industry === '食品業' ? <GriChat surveyJson={survey413food} id="413"/> :<GriChat surveyJson={surveyJson413} id="413"/> },
      { path: "/414", element: <GriChat surveyJson={surveyJson414} id="414"/> },
      { path: "/416", element: <GriChat surveyJson={surveyJson416} id="416"/> },
      { path: "/417", element: industry === '食品業' ? <GriChat surveyJson={survey417food} id="417"/> :<GriChat surveyJson={surveyJson417} id="417"/> },
      { path: "/418", element: <GriChat surveyJson={surveyJson418} id="418"/> },
      { path: "/419", element: <GriChat surveyJson={surveyJson419} id="419"/> },
      { path: "/4", element: <GriChat surveyJson={surveyJson4} id="4"/> },
    ],
  },
]);

export default router;
