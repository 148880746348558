import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Link,
} from "@mui/material";

import SaveButton from "./SaveButton";
import { addEsg, updateEsg } from "api/esgApi.js";
import "survey-core/defaultV2.min.css";
import { useSelector } from "react-redux";

const ContentCard = ({
  title,
  content,
  content_file,
  content_from,
  seqNo,
  templateId,
  type,
  handleChat,
}) => {
  const [editableContent, setEditableContent] = useState(content);
  const isShowWrite = useSelector((state) => state.app.isShowWrite);

  const handleContentChange = (event) => {
    setEditableContent(event.target.innerHTML);
  };

  const handleSave = () => {
    const accessToken = window.localStorage.getItem("accessToken") || "";
    if (seqNo !== 0 && accessToken && content !== "") {
      updateEsg(
        {
          content: content,
          seqNo: seqNo,
        },
        accessToken
      ).then((res) => {
        console.log("updateEsg:", res);
      });
    } else if (seqNo == 0 && accessToken && content !== "") {
      addEsg(
        {
          content: content,
          esgTemplateSeqNo: templateId,
        },
        accessToken
      ).then((res) => {
        console.log("addEsg:", res);
      });
    }
  };


  const [rn1, setRn1] = useState(null);
  const [rn2, setRn2] = useState(null);
  const [rn3, setRn3] = useState(null);
  const [rn4, setRn4] = useState(null);
  const [rn5, setRn5] = useState(null);

  useEffect(() => {
    setRn1(Math.floor(Math.random() * (500 - 100 + 1)) + 100);
    setRn2(Math.floor(Math.random() * (60 - 20 + 1)) + 20);
    setRn3(Math.floor(Math.random() * (99 - 40 + 1)) + 40);
    setRn4(Math.floor(Math.random() * (20000 - 2000 + 1)) + 2000);
    setRn5(Math.floor(Math.random() * (90000 - 60000 + 1)) + 60000);
  }, []);


  return (
    <>
      <Card className="mt-4">
        <CardContent>
          <Typography className="mb-4 text-lg font-bold">{title}</Typography>
          <Typography className="text-black text-lg max-w-[1020px]">
            {/* <div contentEditable={true} onInput={handleContentChange}
            >  <ReactMarkdown>{content}</ReactMarkdown>
            </div> */}
            <ReactMarkdown>{content}</ReactMarkdown>
            {/* {content} */}
            {isShowWrite && <Button variant="text" onClick={handleChat}>AI寫更多</Button>}

          </Typography>
          {content.length > 100 && type === "image" && (
            <div className="relative mt-2">
              <img
                src="/chart/img_chart.png"
                alt="chart"
                width="1000px"
                height="246px"
                className="min-w-[1000px]"
              ></img>
              <div className="absolute bottom-16 left-[80px] text-white p-1">
                {rn1}個
              </div>
              <div className="absolute bottom-16 left-[280px] text-white p-1">
                {rn2} 個
              </div>
              <div className="absolute bottom-16 left-[480px] text-white p-1">
                {rn3} 個
              </div>
              <div className="absolute bottom-16 left-[665px] text-white p-1">
                {rn4} 人
              </div>
              <div className="absolute bottom-16 left-[860px] text-white p-1">
                {rn5} 人
              </div>
            </div>
          )}
        </CardContent>
        <CardActions>
          <div className="flex justify-between items-center">
            {isShowWrite && <SaveButton handleSave={handleSave} />}
            <Typography className="ml-2">
              <Link
                href={content_file}
                target="_blank"
                rel="noopener noreferrer"
                className="opacity-0"
              >
                {content_from}
              </Link>
            </Typography>
         
          </div>
        </CardActions>
      </Card>
    </>
  );
};

export default ContentCard;
