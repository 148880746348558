import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ArchiveIcon from "@mui/icons-material/Archive";

const SaveButton = ({ handleSave }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true);
    isDisabled ===false && handleSave();
  };

  React.useEffect(() => {
    if (isDisabled) {
      const timer = setTimeout(() => {
        setIsDisabled(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isDisabled]);

  return (
    <IconButton aria-label="delete" onClick={handleClick} disabled={isDisabled}>
      <ArchiveIcon />
    </IconButton>
  );
};

export default SaveButton;
