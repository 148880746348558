import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowWrite: false,
  curIndustry:'',
};

export const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    setShowWrite: (state, action) => {
      state.isShowWrite = action.payload;
    },
    setIndustry: (state, action) => {
      state.curIndustry = action.payload;
    },
  },
});

// Actions
export const {
  setShowWrite,  
  setIndustry,
} = appSlice.actions;

export default appSlice.reducer;
