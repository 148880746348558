var width = document.documentElement.clientWidth;

export const surveyJson416 = {
  title: "GRI 416：供應商社會評估 2016",
  logoPosition: "right",
  pages: [
    {
      name: "416-1",
      elements: [
        {
          type: "panel",
          name: "416-1-panel",
          elements: [
            {
              type: "text",
              name: "416-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "416-1 評估產品和服務類別對健康和安全的衝擊",
    },
    {
      name: "416-2",
      elements: [
        {
          type: "panel",
          name: "416-2-panel",
          elements: [
            {
              type: "text",
              name: "416-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "416-2 違反有關產品與服務的健康和安全法規之事件",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
