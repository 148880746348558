import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import { ContentCard1 } from "components/Content1.js";
import { ContentNoSample } from "components/ContentNoSample.js";


function Gri303({ surveyJson, id }) {
  const [survey, setSurvey] = React.useState(null);
  const [page, setPage] = React.useState(`${id}-1`);

  useEffect(() => {
    setSurvey(new Model(surveyJson));
  }, [surveyJson]);

  useEffect(() => {
    if (survey) {
      survey.onValueChanged.add((result, options) => {
        console.log("urvey.onValueChanged ", options);
      });

      survey.onCurrentPageChanged.add(function (sender, options) {
        setPage(options.newCurrentPage.name);
      });
    }
  }, [survey]);

  return (
    <>
      <Box className="w-full flex-row flex">
        {survey ? <Survey model={survey} /> : <Box />}

        <Box
          style={{
            width: "50%",
            background: "#f3f3f3",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <Box className="w-full h-[90px] bg-white bg-no-repeat bg-cover flex justify-between items-center" />
            {page === `${id}-1` && <ContentCard1 survey={survey} id={`${id}-1`} />}
            {page === `${id}-2` && <ContentCard1 survey={survey} id={`${id}-2`} />}
            {page === `${id}-3` && <ContentNoSample survey={survey} id={`${id}-3`} />}
            {page === `${id}-4` && <ContentNoSample survey={survey} id={`${id}-4`} />}
            {page === `${id}-5` && <ContentNoSample survey={survey} id={`${id}-5`} />}
          </Box>
      </Box>
    </>
  );
}

export default Gri303;
