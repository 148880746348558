var width = document.documentElement.clientWidth;

export const surveyJson413 = {
  title: "GRI 413：當地社區2016",
  logoPosition: "right",
  pages: [
    {
      name: "413-1",
      elements: [
        {
          type: "panel",
          name: "413-1-panel",
          elements: [
            {
              type: "text",
              name: "413-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "413-1 經當地社區溝通、衝擊評估和發展計畫的營運活動",
    },
    {
      name: "413-2",
      elements: [
        {
          type: "panel",
          name: "413-2-panel",
          elements: [
            {
              type: "text",
              name: "413-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "413-2 對當地社區具有顯著實際或潛在負⾯衝擊的營運活動",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
