import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import { ContentCard1 } from "components/Content1.js";

function GriOne({ surveyJson, id }) {
  const [survey, setSurvey] = React.useState(null);

  useEffect(() => {
    setSurvey(new Model(surveyJson));
  }, [surveyJson]);

  useEffect(() => {
    if (survey) {
      survey.onValueChanged.add((result, options) => {
        console.log("urvey.onValueChanged ", options);
      });
    }
  }, [survey]);

  return (
    <>
      <Box className="w-full flex-row flex">
        {survey ? <Survey model={survey} /> : <Box />}

        <Box className="w-100 bg-[#f3f3f3]">
          <Box className="w-full h-[90px] bg-white bg-no-repeat bg-cover flex justify-between items-center" />
          <ContentCard1
            survey={survey}
            title={id}
            id={`${id}`}
          />
        </Box>
      </Box>
    </>
  );
}

export default GriOne;
