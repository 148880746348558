import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setShowWrite,
  setIndustry,
} from ".";

export const useSetShowWrite = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setShowWrite(val)), [dispatch]);
};

export const useSetIndustry = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setIndustry(val)), [dispatch]);
};

