import React, { useState } from "react";
import { Button } from "@mui/material";

const ChatButton = ({ handleChat }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true);
    isDisabled ===false && handleChat();

  };

  React.useEffect(() => {
    if (isDisabled) {
      const timer = setTimeout(() => {
        setIsDisabled(false);
      }, 5000); 

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isDisabled]);

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      size="large"
      className="top-11 bg-[#FFF]"
      disabled={isDisabled}
    >
      AI生成
    </Button>
  );
};

export default ChatButton;