var width = document.documentElement.clientWidth;

export const surveyJson401 = {
  title: "GRI 401：勞雇關係 2016",
  logoPosition: "right",
  pages: [
    {
      name: "401-1",
      elements: [
        {
          type: "panel",
          name: "401-1-panel",
          elements: [
            {
              type: "text",
              name: "401-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "401-1 新進員工和離職員工",
    },
    {
      name: "401-2",
      elements: [
        {
          type: "panel",
          name: "401-2-panel",
          elements: [
            {
              type: "text",
              name: "401-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "401-2 提供給全職員工（不包含臨時或兼職員工）的福利",
    },
    {
      name: "401-3",
      elements: [
        {
          type: "panel",
          name: "401-3-panel",
          elements: [
            {
              type: "text",
              name: "401-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "401-3 育嬰假",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
