export const gri_data = [
  { esg: "G", name: "201-1 組織所產生及分配的直接經濟價值", key: "201-1" },
  {
    esg: "G",
    name: "201-2 氣候變遷所產生的財務影響及其它風險與機會",
    key: "201-2",
  },
  { esg: "G", name: "201-3 確定給付制義務與其他退休計畫", key: "201-3" },
  { esg: "G", name: "201-4 取⾃政府之財務援助", key: "201-4" },
  {
    esg: "G",
    name: "203-1 基礎設施的投資與支援服務的發展及衝擊",
    key: "203-1",
  },
  { esg: "G", name: "204-1 來自當地供應商的採購支出比例", key: "204-1" },
  { esg: "G", name: "205-1 已進⾏貪腐風險評估的營運據點", key: "205-1" },
  { esg: "G", name: "205-2 有關反貪腐政策和程序的溝通及訓練", key: "205-2" },
  {
    esg: "G",
    name: "206-1 反競爭⾏為、反托拉斯和壟斷⾏為的法律⾏動",
    key: "206-1",
  },
  { esg: "E", name: "302-1 組織內部的能源消耗量", key: "302-1" },
  { esg: "E", name: "302-2 組織外部的能源消耗量", key: "302-2" },
  { esg: "E", name: "302-3 能源密集度", key: "302-3" },
  { esg: "E", name: "302-4 減少能源消耗", key: "302-4" },
  { esg: "E", name: "303-1 共享水資源之相互影響", key: "303-1" },
  { esg: "E", name: "305-1 直接（範疇一）溫室氣體排放", key: "305-1" },
  { esg: "E", name: "305-2 能源間接（範疇二）溫室氣體排放", key: "305-2" },
  { esg: "E", name: "305-3 其它間接（範疇三）溫室氣體排放", key: "305-3" },
  { esg: "E", name: "305-4 溫室氣體排放密集度", key: "305-4" },
  { esg: "E", name: "305-5 溫室氣體排放減量", key: "305-5" },
  {
    esg: "E",
    name: "305-7 氮氧化物(NOx)、硫氧化物(SOx)，及其它重大的氣體排放",
    key: "305-7",
  },
  { esg: "E", name: "306-1 廢棄物的產生與廢棄物相關顯著衝擊", key: "306-1" },
  { esg: "E", name: "306-2 廢棄物相關顯著衝擊之管理", key: "306-2" },
  { esg: "E", name: "306-3 廢棄物的產生", key: "306-3" },
  { esg: "E", name: "306-4 廢棄物的處置移轉", key: "306-4" },
  { esg: "E", name: "306-5 廢棄物的直接處置", key: "306-5" },
  { esg: "E", name: "308-1 使用環境標準篩選新供應商", key: "308-1" },
  {
    esg: "E",
    name: "308-2 供應鏈對環境的負面衝擊，以及所採取的行動",
    key: "308-2",
  },
  { esg: "S", name: "401-1 新進員工和離職員工", key: "401-1" },
  {
    esg: "S",
    name: "401-2 提供給全職員工（不包含臨時或兼職員工）的福利",
    key: "401-2",
  },
  { esg: "S", name: "403-1 職業安全衛生管理系統", key: "403-1" },
  { esg: "S", name: "403-2 危害辨識、風險評估、及事故調查", key: "403-2" },
  { esg: "S", name: "403-3 職業健康服務", key: "403-3" },
  {
    esg: "S",
    name: "403-4 有關職業安全衛生之工作者參與、諮商與溝通",
    key: "403-4",
  },
  { esg: "S", name: "403-5 有關職業安全衛生之工作者訓練", key: "403-5" },
  { esg: "S", name: "403-6 工作者健康促進", key: "403-6" },
  {
    esg: "S",
    name: "403-7 預防和減輕與業務關係直接相關聯之職業安全衛生的衝擊",
    key: "403-7",
  },
  { esg: "S", name: "403-8 職業安全衛生管理系統所涵蓋之工作者", key: "403-8" },
  { esg: "S", name: "403-9 職業傷害", key: "403-9" },
  { esg: "S", name: "404-1 每名員工每年接受訓練的平均時數", key: "404-1" },
  { esg: "S", name: "404-2 提升員工職能及過渡協助方案", key: "404-2" },
  {
    esg: "S",
    name: "404-3 定期接受績效及職業發展檢核的員工百分比",
    key: "404-3",
  },
  { esg: "S", name: "405-1 治理單位與員工的多元化", key: "405-1" },
  { esg: "S", name: "405-2 女性對男性基本薪資加薪酬的比率", key: "405-2" },
  {
    esg: "S",
    name: "413-1 經當地社區溝通、衝擊評估和發展計畫的營運活動",
    key: "413-1",
  },
  { esg: "S", name: "414-1 使用社會標準篩選新供應", key: "414-1" },
  {
    esg: "S",
    name: "414-2 供應鏈中負面的社會衝擊以及所採取的行動",
    key: "414-2",
  },
  {
    esg: "S",
    name: "416-1 評估產品和服務類別對健康和安全的衝擊",
    key: "416-1",
  },
  {
    esg: "S",
    name: "416-2 違反有關產品與服務的健康和安全法規之事件",
    key: "416-2",
  },
  {
    esg: "S",
    name: "418-1 經證實侵犯客戶隱私或遺失客戶資料的投訴",
    key: "418-1",
  },
  {
    esg: "S",
    name: "419-1 違反社會與經濟領域之法律和規定",
    key: "419-1",
  },
];

export const key_data = {
  "201-1": [
    "您認為組織在過去三年的收入增長情況如何？",
    "您是否認為組織在經濟價值分配上能夠公平對待所有利益相關者（如員工、股東、社區等）？",
    "您認為組織在經濟價值分配過程中透明度如何？",
    "在面臨經濟挑戰時，您對組織應對措施的滿意度如何？",
    "您認為組織的經濟活動對當地社區的經濟發展有何影響？",
  ],
  "201-2": [
    "組織如何評估氣候變遷對其財務狀況的潛在影響（如資產損失、運營成本增加等）？",
    "組織是否有針對氣候變遷風險的具體應對策略或計劃？這些策略如何影響組織的長期財務穩定性？",
    "氣候變遷對組織供應鏈的穩定性和成本控制有哪些潛在風險？",
    "組織是否識別並利用了氣候變遷所帶來的市場機會，如投資於可再生能源或綠色技術？",
    "組織在面對氣候變遷挑戰時，是否進行了投資重新分配或資源優化，以最大化機會並最小化風險？",
  ],
  "201-3": [
    "組織目前的確定給付制義務總額是多少？未來的義務支付是否存在潛在風險？",
    "組織如何管理確定給付制退休計畫的資金，以確保未來能夠滿足支付義務？",
    "組織是否定期評估其退休計畫的財務健康狀況？是否有措施應對資金不足的情況？",
    "組織在確定給付制義務和其他退休計畫方面，是否為員工提供了多樣化的選擇和靈活性？",
    "退休計畫的相關政策是否對組織的長期財務穩定性產生影響？如果是，組織如何應對這些影響？",
  ],
  "201-4": [
    "政府援助的資金是否穩定，是否會受到政策變動的影響？",
    "公司是否過度依賴政府援助，這種依賴程度會如何影響公司的長期可持續發展？",
    "政府援助是否附帶條件或要求？這些條件如何影響公司的運營和策略？",
    "接受政府援助是否會影響公司的公共形象和聲譽？消費者和合作夥伴對此的看法如何？",
    "政府援助如何影響公司的財務報表和稅務？是否需要特殊的會計處理？",
  ],
  "202-1": [
    "基層人員的標準薪資與當地最低薪資的比率是否反映了性別薪資差異？",
    "不同性別的基層人員薪資差異對公司招聘和留任策略有何影響？",
    "基層人員薪資差異是否會影響公司的品牌形象和公共關係？",
    "公司如何應對基層人員性別薪資差異所帶來的法律和合規風險？",
    "薪資差異是否影響了公司在市場上的競爭力和生產力？",
  ],
  "202-2": [
    "雇用當地居民為高階管理階層的比例是否能提高公司的地方市場理解和業務適應能力？",
    "高階管理層當地化是否會影響公司的策略和決策過程？",
    "當地居民在高階管理層的比例對公司形象和公共關係有何影響？",
    "高階管理層的當地化是否會影響公司的招聘和留任策略？",
    "當地高階管理層的比例是否能提高公司的長期經濟績效和競爭力？",
  ],
  "203-1": [
    "基礎設施的投資是否能提高公司的運營效率和生產力？",
    "基礎設施的發展對公司的成本結構和資本支出有何影響？",
    "基礎設施的改進是否能夠增強公司的市場競爭力和業務增長？",
    "基礎設施的投資是否會對公司的財務穩健性和資金流動性造成壓力？",
    "基礎設施的支援服務是否能夠改善公司的客戶服務和滿意度？",
  ],
  "203-2": [
    "公司的經濟活動對當地社區的就業機會有何影響？",
    "公司運營是否對當地經濟活動和商業環境造成顯著影響？",
    "公司的間接經濟衝擊是否影響了地方基礎設施的需求和使用？",
    "公司的經濟活動是否對當地的房地產市場和物價水平造成了變化？",
    "公司的業務運營是否對當地供應鏈和相關行業造成了顯著影響？",
  ],
  "204-1": [
    "來自當地供應商的採購支出比例是否能夠促進當地經濟增長？",
    "當地供應商的採購比例對公司的供應鏈穩定性有何影響？",
    "高比例的當地採購是否能夠提高公司的品牌形象和社會責任感？",
    "當地供應商的採購比例是否影響了公司的成本結構和利潤率？",
    "來自當地供應商的採購是否對公司的合作夥伴關係和市場競爭力產生了影響？",
  ],
  "205-1": [
    "已進行貪腐風險評估的營運據點是否能夠降低公司的法律和財務風險？",
    "貪腐風險評估是否能提高公司對潛在貪腐行為的識別和防範能力？",
    "評估結果是否能夠改善公司內部控制和治理結構？",
    "貪腐風險評估是否對公司的業務運營和市場競爭力產生了積極影響？",
    "評估過程中發現的問題是否能夠改善公司與利益相關者的關係和信譽？",
  ],
  "205-2": [
    "反貪腐政策和程序的溝通是否能夠有效提高員工的合規意識？",
    "訓練計劃是否能夠提升員工識別和報告貪腐行為的能力？",
    "溝通和訓練對公司的貪腐風險管理和內部控制系統有何影響？",
    "反貪腐政策的溝通是否改善了員工對公司治理和道德標準的理解？",
    "反貪腐訓練的質量和頻率是否對減少貪腐事件發生有顯著效果？",
  ],
  "205-3": [
    "已確認的貪腐事件是否對公司的財務狀況和聲譽造成了顯著影響？",
    "公司針對已確認的貪腐事件所採取的行動是否能有效改善內部控制和合規管理？",
    "貪腐事件的處理是否能提高員工對公司道德標準和政策的信任？",
    "採取的行動是否能夠防範未來類似貪腐事件的發生？",
    "已確認的貪腐事件對公司的法律風險和外部監管關係有何影響？",
  ],
  "206-1": [
    "反競爭行為、反托拉斯和壟斷行為的法律行動是否對公司的財務狀況造成了影響？",
    "法律行動是否改變了公司的業務策略和市場競爭方式？",
    "這些法律行動是否影響了公司的市場份額和競爭優勢？",
    "法律行動對公司的聲譽和品牌形象有何影響？",
    "公司是否能有效應對法律行動所帶來的合規挑戰和管理成本？",
  ],
  "207-1": [
    "稅務方針是否能夠有效降低公司的稅務風險和合規成本？",
    "稅務方針對公司的財務績效和現金流有何影響？",
    "稅務方針是否能夠提高公司的稅收效率和稅收規劃能力？",
    "稅務方針是否對公司的投資決策和資本結構產生了影響？",
    "稅務方針對公司與稅務機關的關係和外部審計有何影響？",
  ],
  "207-2": [
    "稅務治理和管控措施是否能有效降低公司的稅務風險？",
    "稅務風險管理策略對公司的財務穩健性和現金流有何影響？",
    "稅務治理是否改善了公司的稅務合規性和內部控制？",
    "稅務風險管理是否影響了公司的稅收成本和稅收效率？",
    "稅務治理和管控對公司與稅務機關的關係和審計過程有何影響？",
  ],
  "207-3": [
    "稅務相關議題的利害關係人溝通是否能有效減少稅務爭議和衝突？",
    "利害關係人對稅務相關問題的管理是否影響公司的聲譽和信任度？",
    "稅務溝通策略是否能夠提高公司在利害關係人中的透明度和合規性？",
    "稅務議題的管理是否對公司的業務運營和決策過程產生影響？",
    "稅務相關議題的溝通和管理是否能改善公司與稅務機關及其他外部機構的關係？",
  ],
  "207-4": [
    "國別報告是否能提高公司在不同國家間的稅務合規性和透明度？",
    "國別報告對公司的財務披露和信息披露要求有何影響？",
    "國別報告是否影響公司在各國市場的稅務策略和風險管理？",
    "國別報告的要求是否對公司的財務報表和報告流程造成了挑戰？",
    "國別報告是否能改善公司與各國稅務機關和利益相關者的關係？",
  ],
  "301-1": [
    "所有物料的重量或體積是否影響了公司的物流成本和運輸效率？",
    "物料的重量或體積是否對倉儲空間和管理造成了壓力？",
    "物料的重量或體積是否影響了產品的包裝和處理成本？",
    "物料的重量或體積是否對環境影響的管理和合規要求有影響？",
    "物料的重量或體積是否影響了公司的供應鏈運作和產品交付時間？",
  ],
  "301-2": [
    "使用回收再利用的物料是否能降低公司的原材料成本？",
    "回收再利用的物料對公司的環境足跡和可持續性目標有何影響？",
    "使用回收再利用物料是否能改善公司的品牌形象和市場競爭力？",
    "回收再利用的物料是否影響了產品的質量和性能？",
    "使用回收再利用物料是否能降低公司的廢料處理和管理成本？",
  ],
  "301-3": [
    "回收的產品和包材是否能降低公司的材料成本和廢料處理費用？",
    "回收包材對公司的環境影響和可持續性績效有何影響？",
    "回收產品和包材是否改善了公司的品牌形象和市場競爭力？",
    "回收包材的使用是否影響了產品的質量和包裝效果？",
    "回收產品和包材的處理和管理是否對公司的操作效率和成本結構產生了影響？",
  ],
  "302-1": [
    "組織內部的能源消耗量是否對公司的運營成本和財務績效產生影響？",
    "能源消耗量是否影響公司的能源效率和環境足跡？",
    "高能源消耗是否對公司的可持續性目標和合規要求造成挑戰？",
    "能源消耗量的變動是否影響了公司的設備和設施維護需求？",
    "能源消耗的管理和減少措施是否能改善公司的品牌形象和市場競爭力？",
  ],
  "302-2": [
    "組織外部的能源消耗量是否影響公司的供應鏈成本和運營效率？",
    "外部能源消耗量是否對公司的環境影響和可持續性績效產生影響？",
    "外部能源消耗的變動是否影響了公司的業務合作夥伴和客戶關係？",
    "組織外部的能源消耗是否對公司的合規要求和法律風險有影響？",
    "外部能源消耗量的變化是否影響公司的市場競爭力和品牌形象？",
  ],
  "302-3": [
    "能源密集度是否影響公司的運營成本和財務績效？",
    "高能源密集度是否對公司的能源效率和環境影響造成挑戰？",
    "能源密集度的變動是否影響公司的可持續性目標和合規要求？",
    "能源密集度是否對公司的設備使用和維護需求產生影響？",
    "能源密集度的改善是否能提升公司的品牌形象和市場競爭力？",
  ],
  "302-4": [
    "減少能源消耗是否能顯著降低公司的運營成本？",
    "減少能源消耗是否改善了公司的環境影響和可持續性績效？",
    "減少能源消耗的措施是否影響了公司的設備和技術投資需求？",
    "減少能源消耗是否對公司的合規性和法律風險產生了正面影響？",
    "減少能源消耗的努力是否能提升公司的品牌形象和市場競爭力？",
  ],
  "302-5": [
    "降低產品和服務的能源需求是否能顯著減少公司的運營成本？",
    "產品和服務能源需求的降低是否能改善公司的環境足跡和可持續性績效？",
    "降低能源需求是否影響了產品的性能和客戶滿意度？",
    "降低產品和服務的能源需求是否對公司的市場競爭力和市場份額產生了影響？",
    "降低能源需求的策略是否會影響公司的技術創新和投資需求？",
  ],
  "303-1": [
    "共享水資源的使用是否對公司的水資源可用性和成本產生影響？",
    "共享水資源的管理是否影響了公司的水質和供應穩定性？",
    "共享水資源的競爭是否對公司與其他使用者的關係和合作造成挑戰？",
    "共享水資源的使用是否對公司的環境影響和可持續性績效產生了影響？",
    "共享水資源的相互影響是否影響了公司的長期水資源規劃和風險管理？",
  ],
  "303-2": [
    "與排水相關衝擊的管理是否能有效降低公司的環境風險和法律合規問題？",
    "排水管理措施是否影響了公司的運營成本和資本支出？",
    "排水衝擊的管理是否能改善公司的社區關係和公共形象？",
    "管理排水衝擊的措施是否對公司的生產流程和效率產生了影響？",
    "與排水相關的管理是否能提高公司的可持續性績效和環境保護標準？",
  ],
  "303-3": [
    "取水量的變動是否影響公司的運營成本和水資源可用性？",
    "取水量是否對公司的環境影響和可持續性績效產生了影響？",
    "高取水量是否會影響公司與當地社區和利益相關者的關係？",
    "取水量的增加是否對公司的水資源管理和風險控制策略產生挑戰？",
    "減少取水量的策略是否能改善公司的品牌形象和市場競爭力？",
  ],
  "303-4": [
    "排水量的變化是否對公司的環境影響和合規要求造成挑戰？",
    "高排水量是否影響了公司的水處理成本和設施運營？",
    "排水量是否對公司的水資源管理和可持續性績效有影響？",
    "排水量的變動是否對公司與社區和環保機構的關係產生了影響？",
    "管理排水量的策略是否能提高公司的品牌形象和市場競爭力？",
  ],
  "303-5": [
    "耗水量的變化是否影響公司的運營成本和水資源供應？",
    "高耗水量是否對公司的環境影響和可持續性績效造成挑戰？",
    "耗水量是否影響公司的合規要求和法律風險？",
    "耗水量的管理是否對公司的水資源策略和風險管理產生影響？",
    "減少耗水量的措施是否能提高公司的品牌形象和市場競爭力？",
  ],
  "304-1": [
    "營運據點位於環境保護區或高生物多樣性價值地區是否影響公司的環境合規成本？",
    "環境保護區或高生物多樣性地區的地點是否對公司的業務操作和生產流程產生挑戰？",
    "擁有或租賃的營運據點是否需要額外的環境保護措施，這對公司的成本有何影響？",
    "營運據點所在的地區是否對公司的品牌形象和市場競爭力產生了影響？",
    "環境保護區或高生物多樣性地區的存在是否對公司的環境風險管理和長期可持續性績效有影響？",
  ],
  "304-2": [
    "活動、產品或服務是否對生物多樣性造成了顯著的負面影響？",
    "生物多樣性的顯著衝擊是否影響了公司的環境合規和法律風險？",
    "活動或產品對生物多樣性的影響是否改變了公司的市場定位和品牌形象？",
    "生物多樣性方面的顯著衝擊是否影響了公司的供應鏈管理和運營效率？",
    "公司是否需要實施額外的措施來減少對生物多樣性的影響，這對公司的成本和資源管理有何影響？",
  ],
  "305-1": [
    "直接溫室氣體排放是否影響公司的碳排放成本和合規支出？",
    "直接排放量的變動是否對公司的環境影響和可持續性績效造成挑戰？",
    "直接溫室氣體排放的管理措施是否影響公司的運營效率和生產成本？",
    "高排放量是否影響了公司與利益相關者和社區的關係？",
    "減少直接溫室氣體排放的措施是否能改善公司的品牌形象和市場競爭力？",
  ],
  "305-2": [
    "能源間接溫室氣體排放是否影響公司的能源成本和供應鏈管理？",
    "高能源間接排放是否對公司的環境影響和可持續性績效造成挑戰？",
    "能源間接排放的變化是否影響了公司的合規要求和法律風險？",
    "管理能源間接排放的措施是否對公司的運營效率和資本支出產生了影響？",
    "減少能源間接溫室氣體排放的策略是否能提高公司的品牌形象和市場競爭力？",
  ],
  "305-3": [
    "其它間接溫室氣體排放是否影響公司的業務運營和供應鏈管理？",
    "高其他間接排放是否對公司的環境影響和可持續性績效造成挑戰？",
    "管理其他間接排放的措施是否對公司的成本結構和資本支出產生了影響？",
    "其他間接溫室氣體排放的變化是否影響了公司的法律合規和風險管理？",
    "減少其他間接溫室氣體排放的策略是否能改善公司的品牌形象和市場競爭力？",
  ],
  "305-4": [
    "溫室氣體排放密集度是否影響公司的碳排放成本和合規支出？",
    "高排放密集度是否對公司的環境影響和可持續性績效造成挑戰？",
    "排放密集度的變化是否影響了公司的運營效率和生產成本？",
    "管理排放密集度的措施是否對公司的法律合規和風險管理產生影響？",
    "降低排放密集度的策略是否能提高公司的品牌形象和市場競爭力？",
  ],
  "305-5": [
    "減少溫室氣體排放是否能顯著降低公司的運營成本？",
    "排放減量措施是否改善了公司的環境影響和可持續性績效？",
    "減少排放量的策略是否影響了公司的生產效率和資本支出？",
    "減排努力是否對公司的法律合規和風險管理產生了影響？",
    "減少溫室氣體排放的措施是否能提升公司的品牌形象和市場競爭力？",
  ],
  "305-6": [
    "破壞臭氧層物質的排放是否影響公司的環境合規成本和法律風險？",
    "這些排放對公司的環境影響和可持續性績效有何影響？",
    "破壞臭氧層物質的排放是否改變了公司的市場定位和品牌形象？",
    "管理破壞臭氧層物質的排放是否影響了公司的運營成本和資本支出？",
    "減少這些排放的措施是否能提高公司的環境績效和市場競爭力？",
  ],
  "305-7": [
    "氮氧化物(NOx)、硫氧化物(SOx)及其他氣體排放是否影響公司的環境合規成本和法律風險？",
    "這些氣體排放對公司的環境影響和可持續性績效有何影響？",
    "管理氮氧化物、硫氧化物及其他氣體的排放是否改變了公司的運營成本和資本支出？",
    "氣體排放量的變動是否對公司的品牌形象和市場競爭力產生了影響？",
    "減少這些氣體排放的措施是否能提高公司的環境績效和社會責任感？",
  ],
  "306-1": [
    "廢棄物的產生是否影響了公司的處理成本和資源管理？",
    "廢棄物相關的顯著衝擊是否對公司的環境影響和可持續性績效造成挑戰？",
    "廢棄物管理措施是否對公司的運營效率和生產流程產生影響？",
    "廢棄物的處理和管理是否改變了公司的品牌形象和市場競爭力？",
    "廢棄物產生量的變化是否影響了公司的合規要求和法律風險？",
  ],
  "306-2": [
    "廢棄物相關顯著衝擊的管理是否能有效降低公司的處理成本和資源需求？",
    "管理廢棄物衝擊是否改善了公司的環境影響和可持續性績效？",
    "廢棄物管理措施是否影響了公司的運營效率和生產流程？",
    "管理廢棄物衝擊的策略是否對公司的品牌形象和市場競爭力產生了影響？",
    "管理廢棄物相關衝擊是否對公司的合規要求和法律風險有顯著影響？",
  ],
  "306-3": [
    "廢棄物的產生是否對公司的處理成本和資源使用產生了顯著影響？",
    "廢棄物產生量的變化是否對公司的環境影響和可持續性績效造成挑戰？",
    "廢棄物產生的增加是否改變了公司的運營流程和生產效率？",
    "廢棄物的產生是否影響了公司的合規要求和法律風險？",
    "廢棄物產生量的變化是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "306-4": [
    "廢棄物的處置移轉是否影響了公司的處理成本和資源管理？",
    "廢棄物處置移轉是否對公司的環境影響和可持續性績效產生挑戰？",
    "管理廢棄物處置移轉的措施是否改變了公司的運營流程和效率？",
    "廢棄物處置移轉是否影響了公司的法律合規性和風險管理？",
    "廢棄物處置移轉的策略是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "306-5": [
    "廢棄物的直接處置是否影響了公司的處理成本和資源配置？",
    "廢棄物直接處置對公司的環境影響和可持續性績效有何影響？",
    "直接處置廢棄物的措施是否改變了公司的運營效率和生產流程？",
    "廢棄物直接處置是否對公司的法律合規性和風險管理產生挑戰？",
    "直接處置廢棄物的策略是否能影響公司的品牌形象和市場競爭力？",
  ],
  "307-1": [
    "違反環保法規是否對公司的法律合規成本和風險管理產生影響？",
    "法規違反是否對公司的環境影響和可持續性績效造成挑戰？",
    "違反環保法規的事件是否改變了公司的品牌形象和市場競爭力？",
    "違規行為的發生是否影響了公司的業務運營和生產流程？",
    "管理違反環保法規的措施是否增加了公司的資本支出和資源需求？",
  ],
  "308-1": [
    "使用環境標準篩選新供應商是否能顯著提高供應鏈的可持續性績效？",
    "環境標準篩選新供應商是否對公司的供應鏈成本和資源配置產生影響？",
    "使用環境標準是否影響了供應商的選擇和合作關係？",
    "環境標準篩選是否對公司的品牌形象和市場競爭力有影響？",
    "篩選符合環境標準的供應商是否提高了公司的環境合規性和風險管理？",
  ],
  "308-2": [
    "供應鏈對環境的負面衝擊是否對公司的環境績效和可持續性目標產生了影響？",
    "這些負面衝擊是否增加了公司的合規成本和法律風險？",
    "採取的行動是否能有效降低供應鏈對環境的負面影響？",
    "管理供應鏈環境衝擊的措施是否影響了公司的運營效率和生產流程？",
    "採取行動的結果是否改善了公司的品牌形象和市場競爭力？",
  ],
  "401-1": [
    "新進員工和離職員工的變動是否影響了公司的招聘成本和人力資源管理？",
    "員工的流動率是否對公司的業務運營和生產效率造成了挑戰？",
    "新進和離職員工的情況是否影響了公司的工作環境和企業文化？",
    "員工變動是否影響了公司的知識保留和技術傳承？",
    "人員流動是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "401-2": [
    "提供給全職員工的福利是否能顯著提高員工的滿意度和留任率？",
    "福利計劃是否影響了公司的招聘成本和人才吸引力？",
    "福利的改善是否對公司的工作環境和員工士氣產生了影響？",
    "提供福利的策略是否對公司的財務支出和資源配置產生挑戰？",
    "福利計劃的質量是否能提升公司的品牌形象和市場競爭力？",
  ],
  "401-3": [
    "育嬰假的提供是否能提高員工的滿意度和留任率？",
    "育嬰假政策是否影響了公司的招聘吸引力和人才吸納？",
    "提供育嬰假是否對公司的運營效率和生產流程造成挑戰？",
    "育嬰假是否改善了公司的工作環境和企業文化？",
    "育嬰假政策是否對公司的財務支出和資源管理產生了影響？",
  ],
  "402-1": [
    "營運變化的最短預告期是否能顯著影響公司的業務計劃和資源配置？",
    "預告期的長短是否影響了公司的風險管理和應對策略？",
    "短預告期是否對公司的生產流程和供應鏈造成了挑戰？",
    "營運變化的預告期是否影響了公司的財務規劃和預算管理？",
    "預告期的短暫性是否對公司的市場競爭力和客戶關係產生了影響？",
  ],
  "403-1": [
    "職業安全衛生管理系統是否能有效降低工作場所的事故和傷害率？",
    "實施職業安全衛生管理系統是否影響公司的運營成本和保險費用？",
    "安全衛生系統的實施是否改善了員工的工作滿意度和生產力？",
    "職業安全衛生管理系統是否提高了公司的合規性和法律風險管理？",
    "系統的運行是否對公司的品牌形象和市場競爭力產生了影響？",
  ],
  "403-2": [
    "危害辨識和風險評估是否能顯著降低工作場所的事故和安全風險？",
    "風險評估和事故調查是否影響了公司的保險費用和事故處理成本？",
    "危害辨識和風險評估的效果是否改善了員工的工作滿意度和生產效率？",
    "事故調查是否提高了公司的安全合規性和風險管理能力？",
    "風險評估和事故調查的結果是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-3": [
    "職業健康服務是否能顯著改善員工的健康狀況和工作效率？",
    "提供職業健康服務是否影響了公司的醫療保險費用和健康管理成本？",
    "職業健康服務的實施是否提高了員工的滿意度和留任率？",
    "職業健康服務是否改善了公司的合規性和法律風險管理？",
    "職業健康服務的質量是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-4": [
    "工作者參與和溝通是否能提高職業安全衛生管理系統的有效性？",
    "職業安全衛生的諮商是否改善了員工對安全措施的接受度和配合度？",
    "工作者參與的程度是否對公司的事故率和安全風險管理產生了影響？",
    "有效的溝通和諮商是否提高了員工的工作滿意度和生產力？",
    "職業安全衛生的參與和溝通是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-5": [
    "職業安全衛生訓練是否能顯著降低工作場所的事故和傷害率？",
    "訓練是否提高了員工對安全規程的理解和遵守程度？",
    "職業安全衛生訓練是否影響了公司的保險費用和事故處理成本？",
    "訓練是否改善了員工的工作滿意度和生產效率？",
    "職業安全衛生訓練是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-6": [
    "工作者健康促進措施是否能顯著改善員工的健康狀況和工作效率？",
    "健康促進活動是否影響了公司的醫療保險費用和健康管理成本？",
    "工作者健康促進是否提高了員工的滿意度和留任率？",
    "健康促進措施是否改善了公司的工作環境和企業文化？",
    "工作者健康促進是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-7": [
    "預防和減輕職業安全衛生衝擊的措施是否有效降低了工作場所的事故和傷害率？",
    "這些措施是否影響了公司的業務運營成本和風險管理？",
    "預防和減輕衝擊的策略是否改善了員工的工作滿意度和生產效率？",
    "職業安全衛生衝擊的管理是否提高了公司的合規性和法律風險控制？",
    "採取預防和減輕措施是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-8": [
    "職業安全衛生管理系統是否能顯著改善所涵蓋工作者的健康和安全狀況？",
    "系統涵蓋的工作者是否因改善的管理措施而經歷了更低的事故率和傷害率？",
    "職業安全衛生管理系統是否提高了工作者的滿意度和留任率？",
    "系統的實施是否影響了公司在工作場所安全方面的合規性和法律風險？",
    "職業安全衛生管理系統的效果是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-9": [
    "職業傷害是否顯著增加了公司的醫療和保險費用？",
    "職業傷害的發生是否影響了公司的生產效率和運營成本？",
    "職業傷害是否對員工的工作滿意度和留任率產生了負面影響？",
    "職業傷害是否提高了公司的法律風險和合規成本？",
    "職業傷害的頻率是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "403-10": [
    "職業病的發生是否顯著增加了公司的醫療和保險費用？",
    "職業病是否影響了公司的生產效率和業務運營？",
    "職業病是否對員工的工作滿意度和留任率產生了負面影響？",
    "職業病的管理是否提高了公司的法律風險和合規成本？",
    "職業病的處理和預防措施是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "404-1": [
    "每名員工每年接受訓練的平均時數是否能顯著提高員工的工作效率和生產力？",
    "訓練時數的增加是否影響了公司的培訓成本和資源分配？",
    "更多的訓練是否改善了員工的滿意度和留任率？",
    "訓練的時數是否提高了公司的合規性和業務能力？",
    "員工接受的訓練是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "404-2": [
    "提升員工職能的方案是否能顯著提高員工的工作效率和生產力？",
    "職能提升方案是否影響了公司的培訓成本和資源分配？",
    "提供過渡協助是否改善了員工的滿意度和留任率？",
    "提升職能及過渡協助是否提高了公司的業務能力和合規性？",
    "職能提升和過渡協助方案是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "404-3": [
    "定期接受績效和職業發展檢核的員工百分比是否能顯著提高整體員工的工作效率和生產力？",
    "高比例的績效檢核是否影響了公司的培訓和發展成本？",
    "定期檢核是否改善了員工的滿意度和留任率？",
    "績效及職業發展檢核是否提高了公司的業務能力和合規性？",
    "定期檢核的實施是否對公司的品牌形象和市場競爭力有影響？",
  ],
  "405-1": [
    "治理單位在多元化決策中如何影響組織的文化和價值觀？",
    "多元化的員工結構對組織績效的影響程度如何？",
    "治理單位是否能有效促進員工間的包容性和協作？",
    "員工的多元化背景對創新和問題解決的影響有多大？",
    "多元化管理政策如何影響治理單位的領導風格和決策過程？",
  ],
  "405-2": [
    "性別薪酬差距對組織內的性別平等有多大影響？",
    "女性基本薪資與男性相比的差異對員工士氣有多大影響？",
    "性別薪酬差距對女性在組織內的晉升機會有多大影響？",
    "女性薪酬比例低於男性對公司整體吸引女性人才的能力有多大影響？",
    "公司政策對縮小女性與男性基本薪資差距的有效性有多大影響？",
  ],
  "406-1": [
    "歧視事件的發生頻率對組織內員工士氣和工作滿意度有多大影響？",
    "組織採取的改善行動對減少歧視事件的發生有多大效果？",
    "歧視事件的處理方式對組織聲譽和外部形象有多大影響？",
    "組織的反歧視政策對提升員工對工作環境的安全感有多大影響？",
    "歧視事件後組織的行動對多元化和包容性文化的塑造有多大影響？",
  ],
  "407-1": [
    "營運據點或供應商是否保障結社自由對員工權益有多大影響？",
    "面臨結社自由風險的營運據點對當地社區與企業關係的影響程度如何？",
    "團體協商權利受限制對員工勞動條件的影響有多大？",
    "供應商是否支持結社自由對公司整體供應鏈穩定性有多大影響？",
    "營運據點面臨結社自由風險對品牌形象和企業社會責任的影響有多大？",
  ],
  "408-1": [
    "營運據點和供應商使用童工對公司聲譽有多大影響？",
    "使用童工的風險對供應鏈的合法性和合規性有多大影響？",
    "童工風險對公司與國際合作夥伴關係的影響程度如何？",
    "使用童工對當地社區和勞動力市場的長期影響有多大？",
    "企業防止童工風險的措施對其可持續發展和社會責任的影響有多大？",
  ],
  "409-1": [
    "強迫或強制勞動風險對公司聲譽和品牌形象的影響有多大？",
    "營運據點或供應商使用強迫勞動對公司合規性和法律風險的影響程度如何？",
    "強迫或強制勞動風險對供應鏈穩定性和可靠性有多大影響？",
    "強制勞動事件對員工士氣和工作環境的影響有多大？",
    "企業應對強迫或強制勞動風險的措施對其可持續發展和社會責任的影響有多大？",
  ],
  "411-1": [
    "侵害原住民權利的事件對公司聲譽和公眾形象的影響有多大？",
    "這些事件對公司與當地社區關係的影響程度如何？",
    "侵害原住民權利對公司經營的法律和合規風險有多大影響？",
    "此類事件對企業的社會責任和可持續發展目標有多大影響？",
    "原住民權利事件對公司在該地區的長期營運和投資計劃有多大影響？",
  ],
  "412-1": [
    "人權檢視或衝擊評估對營運活動合規性和法律風險的影響有多大？",
    "接受人權檢視的營運活動對企業聲譽和社會責任有多大影響？",
    "人權衝擊評估對公司在當地社區的形象和關係有多大影響？",
    "接受人權評估後，對營運活動中的員工待遇和工作環境改善的影響程度如何？",
    "人權檢視或衝擊評估對企業未來發展戰略和投資決策有多大影響？",
  ],
  "412-2": [
    "員工對人權政策或程序的訓練對公司內部合規性和法律風險的影響有多大？",
    "人權政策訓練對提升員工對人權問題敏感性和應對能力的影響程度如何？",
    "員工訓練對公司整體工作環境和員工滿意度的影響有多大？",
    "人權訓練對公司品牌形象和社會責任的影響有多大？",
    "定期的人權政策訓練對公司長期人權合規和風險管理的影響有多大？",
  ],
  "412-3": [
    "載有人權條款的投資協議對企業合規性和法律風險的影響有多大？",
    "已進行人權審查的合約對公司聲譽和社會責任的影響程度如何？",
    "人權條款對投資決策和長期商業關係的影響有多大？",
    "這些投資協議對公司在當地社區的形象和關係有多大影響？",
    "重要合約中人權條款的存在對企業風險管理和合約履行的影響有多大？",
  ],
  "413-1": [
    "當地社區溝通對營運活動的社會接受度和支持度有多大影響？",
    "衝擊評估對營運活動的潛在風險識別和管理的影響程度如何？",
    "社區溝通和發展計畫對企業在當地的關係和合作機會有多大影響？",
    "當地社區的意見對營運活動的可持續性和長期成功的影響有多大？",
    "社區參與和發展計畫對企業品牌形象和社會責任的影響有多大？",
  ],
  "413-2": [
    "對當地社區具有顯著負面衝擊的營運活動對社區健康和安全的影響有多大？",
    "此類負面衝擊對當地經濟和生活水平的影響程度如何？",
    "營運活動對社區環境的潛在破壞程度有多大？",
    "負面衝擊對社區居民的生活質量和福祉的影響有多大？",
    "營運活動對當地社區的長期發展和可持續性有多大影響？",
  ],
  "414-1": [
    "使用社會標準篩選新供應商對供應鏈的合規性和風險管理有多大影響？",
    "社會標準篩選對確保供應商符合企業倫理和社會責任的影響程度如何？",
    "這些標準對新供應商的選擇和質量控制有多大影響？",
    "使用社會標準對公司品牌形象和公眾信任的影響有多大？",
    "社會標準篩選對長期供應鏈穩定性和合作關係的影響有多大？",
  ],
  "414-2": [
    "供應鏈中的負面社會衝擊對公司聲譽和品牌形象的影響有多大？",
    "這些負面衝擊對公司合規性和法律風險的影響程度如何？",
    "所採取的行動對改善供應鏈社會責任和風險管理的效果有多大？",
    "負面衝擊對供應鏈合作夥伴和關係的影響有多大？",
    "採取的行動對長期供應鏈穩定性和可持續發展的影響有多大？",
  ],
  "416-1": [
    "產品和服務類別對消費者健康和安全的潛在風險有多大？",
    "不同產品類別對工作環境安全的影響程度如何？",
    "產品和服務類別對公共健康問題的影響有多大？",
    "評估後的風險管理措施對降低健康和安全衝擊的效果有多大？",
    "產品和服務的健康和安全評估對公司合規性和法律風險的影響有多大？",
  ],
  "416-2": [
    "違反健康和安全法規的事件對公司法律風險和合規性的影響有多大？",
    "此類違規事件對公司聲譽和品牌形象的影響程度如何？",
    "違反法規對消費者健康和安全的影響有多大？",
    "這些事件對公司內部控制和風險管理的影響有多大？",
    "違規事件對公司未來的市場競爭力和業務運營的影響有多大？",
  ],
  "417-1": [
    "產品和服務資訊的透明度對消費者信任和滿意度的影響有多大？",
    "標示要求的遵守程度對法律合規性和風險管理的影響有多大？",
    "產品資訊的準確性對消費者安全和健康的影響有多大？",
    "產品和服務標示的完整性對品牌形象和市場競爭力的影響有多大？",
    "遵循標示要求對降低產品退貨和客訴的影響有多大？",
  ],
  "417-2": [
    "未遵循資訊與標示法規對公司法律風險和罰款的影響有多大？",
    "此類事件對公司聲譽和品牌形象的影響程度如何？",
    "違規事件對消費者安全和健康的影響有多大？",
    "事件對市場競爭力和銷售的影響有多大？",
    "未遵循法規對公司內部控制和合規流程的影響有多大？",
  ],
  "417-3": [
    "未遵循資訊與標示法規的事件對公司的法律風險和財務損失有多大影響？",
    "這些違規事件對消費者對產品信任度和滿意度的影響有多大？",
    "違規事件對公司品牌形象和市場聲譽的損害有多大？",
    "事件對公司內部合規流程和操作標準的影響有多大？",
    "未遵循法規對公司未來業務和市場競爭力的影響有多大？",
  ],
  "418-1": [
    "侵犯客戶隱私或遺失客戶資料的投訴對公司聲譽和品牌形象的影響有多大？",
    "此類事件對客戶信任和滿意度的影響程度如何？",
    "投訴對公司法律風險和潛在罰款的影響有多大？",
    "客戶資料問題對公司內部數據管理和安全措施的影響有多大？",
    "侵犯隱私或遺失資料對公司未來客戶關係和業務運營的影響有多大？",
  ],
  "419-1": [
    "違反社會與經濟領域法律對公司法律風險和財務損失的影響有多大？",
    "此類違規行為對公司聲譽和公眾形象的影響程度如何？",
    "違反法律和規定對公司內部合規程序和控制措施的影響有多大？",
  ],
};
