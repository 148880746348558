import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import { surveyJson2 } from "./survey/2.js";

import "survey-core/defaultV2.min.css";

import { ContentCard2_3 } from "components/ContentCard2_3.js";

import { ContentCard1 } from "components/Content1.js";
import { MenuDialog } from "components/MenuDialog";
import { postAuthLogin } from "./api/esgApi.js";

import { ContentNoSample } from "components/ContentNoSample.js";

function App() {
  const [survey, setSurvey] = React.useState(null);
  const [page, setPage] = React.useState("2-1");

  const isHome = window.location.pathname === "/" ? true : false;
  const [openDialog, setOpenDialog] = React.useState(isHome);
  const accessToken = window.localStorage.getItem("accessToken") || "";

  useEffect(() => {
    if (!accessToken) {
      postAuthLogin({
        username: "user2",
        password: "ABcd1234",
      }).then((res) => {
        window.localStorage.setItem("accessToken", res.token);
        window.localStorage.setItem("refreshToken", res.refreshToken);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    setSurvey(new Model(surveyJson2));
  }, []);

  useEffect(() => {
    if (survey) {
      survey.onValueChanged.add((result, options) => {
        console.log("urvey.onValueChanged ", options);
      });

      survey.onCurrentPageChanged.add(function (sender, options) {
        setPage(options.newCurrentPage.name);
      });
    }
  }, [survey]);

  return (
    <React.Fragment>
      <Box className="w-full flex-row flex">
        {survey ? <Survey model={survey} /> : <Box />}

        <Box
          style={{
            width: "70%",
            background: "#f3f3f3",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <Box className="w-full h-[90px] bg-white bg-no-repeat bg-cover flex justify-between items-center" />
          {page === "2-3" && (
            <ContentCard2_3 survey={survey} title="報告期間" />
          )}
          {page === "2-4" && <ContentNoSample survey={survey} id="2-4" />}
          {page === "2-5" && <ContentNoSample survey={survey} id="2-5" />}

          {[
            1, 2, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
            22, 23, 24, 25, 26, 27, 28, 29, 30,
          ].map(
            (pageId, index) =>
              page === `2-${pageId}` && (
                <ContentCard1 survey={survey} id={`2-${pageId}`} key={index} />
              )
          )}
        </Box>
        <MenuDialog {...{ openDialog, setOpenDialog }} />
      </Box>
    </React.Fragment>
  );
}

export default App;
