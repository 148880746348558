import React, { useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { send, sendTable } from "survey/chatKey.js";
import ChatButton from "components/ChatButton";
import CopyButton from "components/CopyButton";
import ContentCard from "components/ChatContentCard";
import ContentTable from "components/ChatContentTable";
import { useSetShowWrite } from 'state/app/hooks';
import { getTemplate, getEsg } from "api/esgApi";

export const ContentCard1 = ({ survey, id, type }) => {
  const navigate = useNavigate();
  const [content, setContent] = React.useState("");
  const [seqNo, setSeqNo] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [content_title, setContent_title] = React.useState("");
  const [content_from, setContent_from] = React.useState("");
  const [content_file, setContent_file] = React.useState("");
  const [templateId, setTemplateId] = React.useState("");

  const setShowWrite = useSetShowWrite();
  const questionTable = ['2-7','401-1'];
  const templateIdMap = {
    '4-1': 'G4-FS6',
    '4-2': 'G4-FS7',
    '4-3': 'G4-FS8',
    '4-4': 'G4-FS10',
    '4-5': 'G4-FS11',
    '4-6': 'G4-FS14',
  };
  

  const handleSendMore = () => {
    const ask = survey.getQuestionByName(id).value || "";
    const result = {};
    result.content = content;
    send(ask, setContent, result, setShowWrite);
  };
  
  const handleChat = (event) => {

    let ask = survey.getQuestionByName(id).value || "";

    if (accessToken) {

      let templateId = templateIdMap[id] || id;

      getTemplate(templateId, accessToken).then((res) => {
        console.log("getTemplate:", templateId, res);
        setContent_title(res.title);
        setContent_from(res.from);
        setContent_file(res.file);
        setTemplateId(res.seqNo);

        if (id == "2-1") {
          const name = type === 'image' ? "台達電子": survey.getQuestionByName("2-1-a").value;
          const company =  type === 'image' ? "": survey.getQuestionByName("2-1-a-dropdown").value;
          if (!name) {
            alert("no name");
            return;
          }
          let replaceName = name + company;
          ask = ask + ' 並加入公司名稱 ' + replaceName ;
          send(ask, setContent, res, setShowWrite);
  
        }  else if ( questionTable.includes(id)) {
          sendTable(ask, setContent, res);
        } else {
          // setShowWrite(true);
          send(ask, setContent, res, setShowWrite);
        }
      });
    }
  };

  const accessToken = window.localStorage.getItem("accessToken") || "";

  useEffect(() => {
    if (accessToken === "undefined") {
      window.localStorage.removeItem("accessToken");
      navigate("/");
      return;
    }

    if (accessToken) {
      setContent("");


      let templateId = templateIdMap[id] || id;

      getEsg(templateId, accessToken).then((res) => {
        setLoading(false);
        console.log("getEsg", id, res);

        if (!res) {
          setContent("");
          setSeqNo(0);
          return;
        }

        if (res && res.seqNo !== 0) {
          setContent_title(res.title);
          setContent(res.content);
          setSeqNo(res.seqNo);
          setShowWrite(true);
        }
      });
    }
  }, [accessToken, id]);

  if (content === "") {
    return (
      <>
        {loading && (
          <div className="relative top-[-1.3px]">
            <LinearProgress color="success" className="h-[1.5px]" />
          </div>
        )}
        <ChatButton handleChat={handleChat} />
      </>
    );
  }

  return (
    <>
      <Box className="h-[106px]">
        <ChatButton handleChat={handleChat} />
        <CopyButton text={`${content}`} />
      </Box>
      <Box className="mr-4">
         {!questionTable.includes(id) &&<ContentCard
          title={content_title}
          content={content}
          content_file={content_file}
          content_from={content_from}
          seqNo={seqNo}
          templateId={templateId}
          type={type}
          handleChat={handleSendMore}
        />}
        {questionTable.includes(id) && <ContentTable
          title={content_title}
          content={content}
          content_file={content_file}
          content_from={content_from}
          seqNo={seqNo}
          templateId={templateId}
        />}
      </Box>
    </>
  );
};
