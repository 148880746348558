var width = document.documentElement.clientWidth;

export const surveyJson417 = {
  title: "GRI 417：責任行銷與標示 2016",
  logoPosition: "right",
  pages: [
    {
      name: "417-1",
      elements: [
        {
          type: "panel",
          name: "417-1-panel",
          elements: [
            {
              type: "text",
              name: "417-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "417-1 產品和服務資訊與標示的要求",
    },
    {
      name: "417-2",
      elements: [
        {
          type: "panel",
          name: "417-2-panel",
          elements: [
            {
              type: "text",
              name: "417-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "417-2 未遵循產品與服務之資訊與標示相關法規的事件",
    },
    {
      name: "417-3",
      elements: [
        {
          type: "panel",
          name: "417-3-panel",
          elements: [
            {
              type: "text",
              name: "417-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "417-3 未遵循產品與服務之資訊與標示相關法規的事件",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
