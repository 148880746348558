var width = document.documentElement.clientWidth;

export const surveyJson202 = {
  title: "GRI 202：市場地位 2016",
  logoPosition: "right",
  pages: [
    {
      name: "202-1",
      elements: [
        {
          type: "panel",
          name: "202-1-panel",
          elements: [
            {
              type: "text",
              name: "202-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "202-1 不同性別的基層人員標準薪資與當地最低薪資的比率",
    },
    {
      name: "202-2",
      elements: [
        {
          type: "panel",
          name: "202-2-panel",
          elements: [
            {
              type: "text",
              name: "202-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "202-2 雇用當地居民為高階管理階層的比例",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  // completeText: "Submit",
  widthMode: "static",
  width: width,
};
