const titles = [
  "G4-FS6 公司各項產品占總營收的比例（依照不同的地區／規模／產業劃分",
  "G4-FS7 為帶來社會公益推出之產品或服務",
  "G4-FS8 為帶來環境保護的目的或功能推出之產品或服務",
  "G4-FS10 於投資組合中就環境或社會面向進行議合的企業占比",
  "G4-FS11 與環境或社會篩選有關之正面及負面影響所占資產的百分比",
  "G4-FS14 金融包容性設計或推動的倡議行動"
];

const pages = titles.map((title, index) => ({
  name: `4-${index + 1}`,
  elements: [
    {
      type: "panel",
      name: `4-${index + 1}-panel`,
      elements: [
        {
          type: "text",
          name: `4-${index + 1}`,
          title: "指令關鍵字",
        },
      ],
    },
  ],
  title: title,
}));

export const surveyJson4 = {
  title: "G4：金融業補充指南",
  logoPosition: "right",
  pages: pages,
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: document.documentElement.clientWidth,
};