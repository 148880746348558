var width = document.documentElement.clientWidth;

export const surveyJson419 = {
  title: "GRI 419：社會經濟學合規",
  logoPosition: "right",
  pages: [
    {
      name: "419-1",
      elements: [
        {
          type: "panel",
          name: "419-1-panel",
          elements: [
            {
              type: "text",
              name: "419-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "419-1 違反社會與經濟領域之法律和規定",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
