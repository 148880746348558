var width = document.documentElement.clientWidth;

export const surveyJson302 = {
  title: "GRI 302：能源 2016",
  logoPosition: "right",
  pages: [
    {
      name: "302-1",
      elements: [
        {
          type: "panel",
          name: "302-1-panel",
          elements: [
            {
              type: "text",
              name: "302-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "302-1 組織內部的能源消耗量",
    },
    {
      name: "302-2",
      elements: [
        {
          type: "panel",
          name: "302-2-panel",
          elements: [
            {
              type: "text",
              name: "302-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "302-2 組織外部的能源消耗量",
    },
    {
      name: "302-3",
      elements: [
        {
          type: "panel",
          name: "302-3-panel",
          elements: [
            {
              type: "text",
              name: "302-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "302-3 能源密集度",
    },
    {
      name: "302-4",
      elements: [
        {
          type: "panel",
          name: "302-4-panel",
          elements: [
            {
              type: "text",
              name: "302-4",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "302-4 減少能源消耗",
    },
    {
      name: "302-5",
      elements: [
        {
          type: "panel",
          name: "302-5-panel",
          elements: [
            {
              type: "text",
              name: "302-5",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "302-5 降低產品和服務的能源需求",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
