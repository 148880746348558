var width = document.documentElement.clientWidth;

export const surveyJson414 = {
  title: "GRI 414：供應商社會評估 2016",
  logoPosition: "right",
  pages: [
    {
      name: "414-1",
      elements: [
        {
          type: "panel",
          name: "414-1-panel",
          elements: [
            {
              type: "text",
              name: "414-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "414-1 使用社會標準篩選新供應",
    },
    {
      name: "414-2",
      elements: [
        {
          type: "panel",
          name: "414-2-panel",
          elements: [
            {
              type: "text",
              name: "414-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "414-2 供應鏈中負面的社會衝擊以及所採取的行動",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  widthMode: "static",
  width: width,
};
