var width = document.documentElement.clientWidth;

export const surveyJson201 = {
  title: "GRI 201：經濟績效 2016",
  logoPosition: "right",
  pages: [
    {
      name: "201-1",
      elements: [
        {
          type: "panel",
          name: "201-1-panel",
          elements: [
            {
              type: "text",
              name: "201-1",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "201-1 組織所產生及分配的直接經濟價值",
    },
    {
      name: "201-2",
      elements: [
        {
          type: "panel",
          name: "201-2-panel",
          elements: [
            {
              type: "text",
              name: "201-2",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "201-2 氣候變遷所產生的財務影響及其它風險與機會",
    },
    {
      name: "201-3",
      elements: [
        {
          type: "panel",
          name: "201-3-panel",
          elements: [
            {
              type: "text",
              name: "201-3",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "201-3 確定給付制義務與其他退休計畫",
    },
    {
      name: "201-4",
      elements: [
        {
          type: "panel",
          name: "201-4-panel",
          elements: [
            {
              type: "text",
              name: "201-4",
              title: "指令關鍵字",
            },
          ],
        },
      ],
      title: "201-4 取⾃政府之財務援助",
    },
  ],
  showQuestionNumbers: "off",
  showTOC: true,
  // completeText: "Submit",
  widthMode: "static",
  width: width,
};
