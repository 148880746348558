import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Bar from "components/Bar";
var height = document.documentElement.clientHeight;
export default function LayoutApp() {
  return (
    <>
      <Bar />
      <Box
        style={{
          position: "fixed",
          width: "100%",
          top: 32, //'auto',
          height: height - 32,
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}
