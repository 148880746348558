import Swal from 'sweetalert2';
import { sendChat } from "../api/getMainnetInfuraApi";

let isReading = false;

export const send = (keyword, setContent, template, setShowWrite) => {
  setShowWrite(false);
  if (isReading) {
    isReading = false;
    send(keyword, setContent, template, setShowWrite);
    return;
  }
  const askMsg = " 以上段落加入" + keyword + " 並重寫文章";

  sendChat({
    message: template.content + askMsg,
  })
    .then((stream) => {
      const reader = stream.getReader();
      var response = "";
      isReading = true;
      (async () => {
        while (isReading) {
          const { done, value } = await reader.read();
          if (done) {
            setShowWrite(true);
            break;
          }
          var text = new TextDecoder("utf-8").decode(value);
          response += text;
   
          setContent(response);
        }
      })();
    })
    .catch((error) => {
      Swal.fire({
        icon: 'info',
        title: '系統維護',
        text: '我們的系統正在進行維護。我們將儘快恢復服務，請稍後再試。',
        confirmButtonText: '確定'
      });
      console.log(`連接失敗: ${error.message}`);
    });
};

export const sendTable = (keyword, setContent, template) => {
  const askMsg = " 以上段落加入" + keyword + " 移除資料為0的  將上述資訊用csv呈現"; // 並重寫文章 移除資料為0的 資料移除臨時 只用性別分類
  sendChat({
    message: template.content + askMsg,
  })
    .then((stream) => {
      const reader = stream.getReader();
      var response = "";
      (async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          var text = new TextDecoder("utf-8").decode(value);
          response += text;

          setContent(response);
        }
      })();
    })
    .then(() => {

    });
};



export const sendFilter = (curNum, gri_data, curIndustry, callback) => {

  const askMsg = " 根據ESG資料 陣列" + gri_data +  " 參考產業 "  + curIndustry + " 從中選取"+curNum+"個 作為重大議題 請只回傳你選擇項目的陣列index,  例如 [1, 3, 9, 12, 14, 25, 27, 32, 45, 47], 提示不是 [201-2, 203-1, 302-1, 302-4, 305-1, 305-5, 306-1, 403-1, 404-1, 416-1] 格式 , 不用引言而且無需給出原因 只給陣列";

  sendChat({
    message: askMsg,
  })
    .then((stream) => {
      const reader = stream.getReader();
      var response = "";

      (async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            callback(response);
            break;
          }
          var text = new TextDecoder("utf-8").decode(value);
          response += text;
          // console.log(response);
        }
      })();
    })
    .catch((error) => {
      Swal.fire({
        icon: 'info',
        title: '系統維護',
        text: '我們的系統正在進行維護。我們將儘快恢復服務，請稍後再試。',
        confirmButtonText: '確定'
      });
      console.log(`連接失敗: ${error.message}`);
    });
};


