import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Link,
} from "@mui/material";
import SaveButton from "./SaveButton";
import { addEsg, updateEsg } from "api/esgApi.js";
// import { Pie } from 'react-chartjs-2';
import "survey-core/defaultV2.min.css";
import { CsvToHtmlTable } from 'react-csv-to-table-18'
import { Chart } from "react-google-charts";

const ContentTable = ({
  title,
  content,
  content_file,
  content_from,
  seqNo,
  templateId,
}) => {
  const handleSave = () => {
    const accessToken = window.localStorage.getItem("accessToken") || "";
    if (seqNo !== 0 && accessToken && content !== "") {
      updateEsg(
        {
          content: content,
          seqNo: seqNo,
        },
        accessToken
      ).then((res) => {
        console.log("updateEsg:", res);
      });
    } else if (seqNo == 0 && accessToken && content !== "") {
      addEsg(
        {
          content: content,
          esgTemplateSeqNo: templateId,
        },
        accessToken
      ).then((res) => {
        console.log("addEsg:", res);
      });
    }
  };
  

  const numbers = content.match(/\d+/g)?.map(Number); // 使用正則表達式來匹配所有的數字

  const male = numbers && numbers[0] || 60;
  const female = numbers && numbers[1] || 60;

  console.log(numbers, male, female);
  const data = [
    ["Task", "male female ratio"],
    ["男性", male],
    ["女性", female],
  ];
  const options = {
    // legend: "none",
    // pieSliceText: "none",
    // pieStartAngle: 135,
    // tooltip: { trigger: "none" },
    slices: {
      0: { color: "LightSkyBlue" },
      1: { color: "LightPink" },
    },
  };
  return (
    <>
      <Card className="mt-4">
        <CardContent>
          <Typography className="mb-4 text-lg font-bold">{title}</Typography>
          {/* <Typography className="text-black text-lg">{content}</Typography> */}
          <CsvToHtmlTable data={content} csvDelimiter="," />
          {/* <Pie data={data} /> */}
          <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width={"100%"}
              height={"400px"}
          />

        </CardContent>
        <CardActions>
          <div className="flex justify-between items-center">
            <Typography className="ml-2">
              <Link
                href={content_file}
                target="_blank"
                rel="noopener noreferrer"
              >
                {content_from}
              </Link>
            </Typography>
            {templateId && <SaveButton handleSave={handleSave} />}
          </div>
        </CardActions>
      </Card>
    </>
  );
};

export default ContentTable;
